import classNames from "classnames";
import gsap from "gsap";
import Image from "next/image";
import { MouseEvent, useEffect, useRef, useState } from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import useWindowSize from "../../../hooks/useWindowSize";
import Button from "../../atoms/button/Button";
import styles from "./LoaderOverlay.module.scss";

type LoaderOverlayProps = {
  onButtonClick: () => void;
  hidden: boolean;
};

export default function LoaderOverlay({
  onButtonClick,
  hidden,
}: LoaderOverlayProps) {
  const { trackButtonClick } = useAnalytics();
  const { width } = useWindowSize();
  const [imageHeight, setImageHeight] = useState(68);
  const [imageWidth, setImageWidth] = useState(350);
  const textContainerRef = useRef(null);
  const imageContainerRef = useRef(null);
  const imageRef = useRef(null);
  const [bgImageLoaded, setBgImageLoaded] = useState(false);

  useEffect(() => {
    setImageHeight(width > 580 ? 110 : 68);
    setImageWidth(width > 580 ? 570 : 350);
  }, [width]);

  function onNextButtonClick(e: MouseEvent<HTMLButtonElement>) {
    trackButtonClick("navigation", "", e.currentTarget.innerText);
    onButtonClick();
  }

  useEffect(() => {
    if (bgImageLoaded) {
      gsap
        .timeline()
        .fromTo(
          imageRef.current,
          {
            scale: 0.5,
          },
          {
            scale: 1,
            duration: 0.8,
            opacity: 1,
            ease: "Power2.easeIn",
          },
          "<"
        )
        .fromTo(
          textContainerRef.current,
          {
            opacity: 0,
            scale: 0.5,
            xPercent: -50,
            yPercent: -50,
          },
          {
            opacity: 1,
            scale: 1,
            xPercent: -50,
            yPercent: -50,
            delay: 0.6,
            duration: 0.6,
            ease: "Power3.easeInOut",
          },
          "<-=0.2"
        );
    }
  }, [bgImageLoaded]);

  function onImageLoaded() {
    setBgImageLoaded(true);
  }

  return (
    <div
      className={classNames(styles.loaderOverlay, {
        [styles.hidden]: hidden,
      })}
    >
      <div className={styles.textContainer} ref={textContainerRef}>
        <Image
          src="/garage_mentality.png"
          alt="Garage mentality logotype"
          width={imageWidth}
          height={imageHeight}
          quality={100}
          className={styles.logo}
          priority
        />
        <p className={classNames(styles.subtitle)}>
          Laat je inspireren door succesvolle ondernemers met behulp van AI.
        </p>
        <Button
          label="Ontdek jouw Garage Mentality"
          onClick={onNextButtonClick}
          buttonIcon={"arrow-right"}
        />
      </div>
      <div className={styles.backgroundContainer} ref={imageContainerRef}>
        <Image
          src="/assets/textures/intro_garage_bg2.jpg"
          alt=""
          className={styles.background}
          priority
          fill
          onLoad={onImageLoaded}
          ref={imageRef}
        />
      </div>
    </div>
  );
}
