import { useFrame, useLoader } from "@react-three/fiber";
import { useScroll } from "@react-three/drei";
import { useRef } from "react";
import {
  MathUtils,
  Mesh,
  TextureLoader,
  MeshBasicMaterial,
  PlaneGeometry,
} from "three";

export default function Scribble({
  src,
  ratioHeight,
  ...props
}: {
  src: string;
  ratioHeight: number;
} & JSX.IntrinsicElements["mesh"]): JSX.Element | null {
  const texture = useLoader(TextureLoader, src);
  const scroll = useScroll();
  const scribbleRef = useRef<Mesh<PlaneGeometry, MeshBasicMaterial>>(null);

  useFrame((state, delta) => {
    if (scribbleRef.current) {
      const visible = scroll.visible(1 / 18, 17 / 18);
      scribbleRef.current.material.opacity = MathUtils.damp(
        scribbleRef.current.material.opacity,
        visible ? 1 : 0,
        2,
        delta
      );
    }
  });
  return (
    <mesh {...props} ref={scribbleRef}>
      <planeGeometry attach="geometry" args={[1, ratioHeight]} />
      <meshBasicMaterial
        attach="material"
        map={texture}
        transparent={true}
        color={0xff0000}
      />
    </mesh>
  );
}
