import { PerspectiveCamera, useScroll } from "@react-three/drei";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { easing } from "maath";
import { Vector3 } from "three";
import { useIsTouchDevice } from "../../../../../hooks/useDevice";
// import { useControls } from "leva";
import gsap from "gsap";

type CameraProps = {
  start?: boolean;
  onIntroAnimationComplete: () => void;
  onStartScrolling: () => void;
  allowScrolling: boolean;
};

const cameraPosition = new Vector3(0, 2, 16);
const cameraStartPositionZ = 10;

export default function Camera({
  start,
  onIntroAnimationComplete,
  onStartScrolling,
  allowScrolling,
}: CameraProps) {
  const allowFreeRotation = false;
  // const { allowFreeRotation } = useControls(
  //   "Camera",
  //   {
  //     allowFreeRotation: false,
  //   },
  //   { collapsed: true }
  // );
  const { camera } = useThree();
  const scroll = useScroll();
  const cameraLookAt = useRef(new Vector3(0, 2, -20)).current;
  const isTouchDevice = useIsTouchDevice(true);
  const oldStart = useRef(false);
  const cameraPositionZ = useRef(cameraPosition.z);

  const [hasScrolled, setHasScrolled] = useState(false);

  useFrame((state, delta) => {
    if (start) {
      if (!allowScrolling) {
        camera.position.z = cameraPositionZ.current;
      } else {
        camera.position.z = cameraStartPositionZ - scroll.offset * 26;

        if (scroll.offset !== 0) {
          setHasScrolled(true);
        }
      }

      if (!isTouchDevice) {
        easing.damp3(
          cameraLookAt,
          [
            state.pointer.x *
              state.viewport.width *
              (allowFreeRotation ? 1.5 : 0.0065),
            2 + state.pointer.y * (allowFreeRotation ? 2 : 0.1),
            camera.position.z - 2,
          ],
          0.2,
          delta,
          Infinity
        );
        camera.lookAt(cameraLookAt.x, cameraLookAt.y, camera.position.z - 2);
      }
    }
  });

  useEffect(() => {
    camera.lookAt(cameraLookAt);
  }, [camera, cameraLookAt]);

  const handleIntroAnimationComplete = useCallback(() => {
    if (onIntroAnimationComplete) onIntroAnimationComplete();
  }, [onIntroAnimationComplete]);

  useEffect(() => {
    if (start && !oldStart.current) {
      gsap.to(cameraPositionZ, {
        current: cameraStartPositionZ,
        onComplete: handleIntroAnimationComplete,
        duration: 1.5,
        ease: "Power1.easeOut",
      });
      oldStart.current = start;
    }
  }, [handleIntroAnimationComplete, start]);

  useEffect(() => {
    if (hasScrolled) {
      onStartScrolling();
    }
  }, [hasScrolled, onStartScrolling]);

  return (
    <PerspectiveCamera
      makeDefault
      fov={50}
      position={cameraPosition}
      near={0.1}
      far={25}
    />
  );
}
