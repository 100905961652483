export const DUTCH_SWEAR_WORDS = {
  // from https://scheldwoorden.goedbegin.nl/
  "achterlijk gebakkie": true,
  "afbak hoer": true,
  "afgebakken frikandelhoofd": true,
  "afgebefte del": true,
  "anussap-verzuring": true,
  "archeologisch kontfossiel": true,
  "arroganteklitzuigende-wrattenhoofd": true,
  "aso-big": true,
  "baarmoeder-discokip": true,
  "baarmoederscheefgepoept-kuttekind": true,
  "carnavals-mussolini": true,
  "darmflora flikker": true,
  "darmgas-wildebras": true,
  "falliete tiet": true,
  "fatsige spekhond": true,
  "fietsendief zonder hersens": true,
  "haagse wim": true,
  "halve maan-rover": true,
  "ingegroeide konthaar": true,
  "ini-mini-scheefgepoepte-pornokabouter": true,
  "internetverslaafde-neuker": true,
  "jam-bek": true,
  "kaalgeschoren bouvier": true,
  "kaalhoofdige kontebonker": true,
  "kakkige kakkerlak": true,
  kraaienkut: true,
  "l-u-l": true,
  "l,u,l": true,
  "l.u.l": true,
  "langharige beftekkel": true,
  "palingstekker(homo)": true,
  "pappige luller": true,
  "passoa-slet": true,
  "piemelhoofdje(s)": true,
  "pik omhoog": true,
  "pik-omhoog": true,
  "pik-omlaag": true,
  pikomhoog: true,
  pikomlaag: true,
  schaamhaarepilerendekontvingeraar: true,
  "uitgelekte theezak": true,
  wasbakkenpisser: true,
  "zaadvragende-budcontainer": true,
  aalskakker: true,
  aambeienbeffer: true,
  aambeienlikker: true,
  aambeienschoffelaar: true,
  aambeihaarwasser: true,
  aapjesviller: true,
  aaprikaan: true,
  aardappelhoer: true,
  aars: true,
  aarsaap: true,
  aarsatleet: true,
  aarsbaard: true,
  aarsbaars: true,
  aarsbanaan: true,
  aarsbeer: true,
  aarsbeffer: true,
  aarselaar: true,
  aarsfreter: true,
  aarsgarnaal: true,
  aarshaar: true,
  aarshaarfohner: true,
  aarshaarknabbelaar: true,
  aarshaarluis: true,
  aarshaarverzamelaar: true,
  aarsketser: true,
  aarskind: true,
  aarsklodder: true,
  aarslander: true,
  aarslikker: true,
  aarslog: true,
  aarsmade: true,
  aarsneger: true,
  aarsridder: true,
  aarsvijand: true,
  abortuskind: true,
  achterbaksestoephoer: true,
  achterbuurtfossiel: true,
  achterlijke: true,
  achterpoter: true,
  adder: true,
  adderengebroedsels: true,
  addergebroed: true,
  afrotten: true,
  anuspapegaai: true,
  anuspenetreerder: true,
  anuspieper: true,
  anuspiloot: true,
  anuspiraat: true,
  anuspuntverknetteraar: true,
  anusreflector: true,
  anusridder: true,
  anussabbelaar: true,
  anustoerist: true,
  anusuitscheiding: true,
  anusvreter: true,
  apekloot: true,
  apekop: true,
  apenbatser: true,
  apeneuker: true,
  apenflikkerneuker: true,
  apengaper: true,
  apenhaar: true,
  apenjong: true,
  apenkont: true,
  apenkut: true,
  apenrukker: true,
  apenwaffel: true,
  appelflap: true,
  appelzwijn: true,
  arrefakker: true,
  asbak: true,
  asbestmuis: true,
  asfaltridder: true,
  asielzoeker: true,
  asshole: true,
  augurkenneuker: true,
  augurkenturk: true,
  augurklul: true,
  autist: true,
  autocraat: true,
  autodidact: true,
  autokraat: true,
  avocadoneuker: true,
  baarvader: true,
  babaloekoe: true,
  babbalipviskikker: true,
  babok: true,
  babypoedersnuiver: true,
  baccilledrager: true,
  badaap: true,
  badgast: true,
  badjufbeffer: true,
  badmuts: true,
  bafaap: true,
  baffer: true,
  baggerduiker: true,
  bangerd: true,
  barslet: true,
  bedrijfspoedel: true,
  beheime: true,
  bermslet: true,
  bitch: true,
  bleekscheet: true,
  bloedlijer: true,
  bosneuker: true,
  braadvarken: true,
  breezerslet: true,
  brilsmurf: true,
  broodaap: true,
  bruinwerker: true,
  bulderbast: true,
  cabaretlul: true,
  camperhoer: true,
  campinghoer: true,
  campingslet: true,
  campusnerd: true,
  camslet: true,
  capsoneslijer: true,
  capsonestrut: true,
  carnavalskaper: true,
  charlatan: true,
  choco: true,
  chocoladehoer: true,
  chocoladesnol: true,
  cijferneuker: true,
  coronalijer: true,
  covidioot: true,
  daaglaker: true,
  dameskapper: true,
  damhoer: true,
  darmafval: true,
  darmcoureur: true,
  darmfloraflirter: true,
  darmkikker: true,
  darmneuker: true,
  darmstad: true,
  darmtoerist: true,
  del: true,
  deuggleuf: true,
  dirkdoos: true,
  dreuzel: true,
  droplul: true,
  druiloor: true,
  dwaas: true,
  ebverzuiper: true,
  ectoplasma: true,
  edeldarm: true,
  eeltneus: true,
  eendeneikel: true,
  eendenkont: true,
  egocentrischebullebak: true,
  eierzak: true,
  eikel: true,
  eikelbijter: true,
  eikelkluiver: true,
  eikellikker: true,
  engerling: true,
  fabricagefout: true,
  facadeklasher: true,
  familycocktail: true,
  fatsich: true,
  fielt: true,
  fietspompenlullegezicht: true,
  fileklever: true,
  flapdrol: true,
  flapkut: true,
  flappie: true,
  flathoer: true,
  flatslet: true,
  flauwerd: true,
  flessentrekker: true,
  flikker: true,
  foefkop: true,
  gadoodengeefmijbrood: true,
  gajeskop: true,
  galbak: true,
  galgenaas: true,
  galgenbrok: true,
  galgengebroed: true,
  galkankerdebiel: true,
  galsmoel: true,
  gambaslet: true,
  gangster: true,
  gannef: true,
  geitenbreier: true,
  geitenneuker: true,
  geteisem: true,
  gluiper: true,
  gluiperd: true,
  gluipsnor: true,
  greppeldel: true,
  grobbejanus: true,
  haaibaai: true,
  haas: true,
  hakker: true,
  halvegare: true,
  halvezool: true,
  hamerhomo: true,
  hammehapper: true,
  hamsteraar: true,
  hamsterpampe: true,
  hamsterpijper: true,
  handvat: true,
  hangbuikvarkenkonteneuker: true,
  hangbuikzwijn: true,
  hapsnurker: true,
  harpij: true,
  heaumeau: true,
  heihaas: true,
  heikneuter: true,
  hersenlijer: true,
  hoer: true,
  hoerenjager: true,
  hoerenjong: true,
  hoerenkind: true,
  hoerenzoon: true,
  homo: true,
  hondenlul: true,
  hondenneuker: true,
  hufter: true,
  huppelkut: true,
  inktlul: true,
  inktviskut: true,
  insekt: true,
  internetslet: true,
  invertebraat: true,
  jambekslikker: true,
  jandoedel: true,
  janettescheter: true,
  janksnor: true,
  janlul: true,
  jeannette: true,
  jenevertrut: true,
  jetonslikker: true,
  jetslet: true,
  kaalkop: true,
  kaapstander: true,
  kaashaas: true,
  kaashoer: true,
  kaasknobbel: true,
  kaaskop: true,
  kaasnicht: true,
  kaasrandknabbelaar: true,
  kadaver: true,
  kaffer: true,
  kaffir: true,
  kak: true,
  kakbal: true,
  kakboer: true,
  kakbroek: true,
  kakhuis: true,
  kakker: true,
  kakkerlak: true,
  kakkie: true,
  kakmadam: true,
  kakmaker: true,
  kakteef: true,
  kamelenneuker: true,
  kankerhoer: true,
  kankerhond: true,
  kankerlijder: true,
  kankerlijer: true,
  kankernicht: true,
  kantoorpik: true,
  kapsoneslijer: true,
  karhengst: true,
  keilef: true,
  kelerelijer: true,
  keutelkut: true,
  kippenneuker: true,
  klapkut: true,
  klapluis: true,
  klaplul: true,
  klapperaap: true,
  klere: true,
  kloefkapper: true,
  klojo: true,
  klooi: true,
  kloothannes: true,
  kloothommel: true,
  klootviool: true,
  klootzak: true,
  klote: true,
  knijpkont: true,
  koeskoesvreter: true,
  koffieboon: true,
  kokosmakroon: true,
  kontkruiper: true,
  kontneuker: true,
  kreng: true,
  kringspiermusketier: true,
  kroeskop: true,
  kruimelbuik: true,
  kruiper: true,
  kut: true,
  kutstreek: true,
  kuttenkop: true,
  kutvent: true,
  kutwijf: true,
  labbekak: true,
  labrat: true,
  lafaard: true,
  lafbek: true,
  lamagezicht: true,
  lambak: true,
  lamlul: true,
  lampekap: true,
  lampelul: true,
  lampenhoer: true,
  lamstraal: true,
  lamzak: true,
  landloper: true,
  langstaart: true,
  lantaarnpaalhoer: true,
  lantarenslet: true,
  lapzwans: true,
  latexnicht: true,
  lauwbikker: true,
  lauwwaterdrinker: true,
  lavabosmoel: true,
  lawaaipapegaai: true,
  leipo: true,
  lekkerpieper: true,
  lelijkerd: true,
  lijer: true,
  linkmiegel: true,
  lomperd: true,
  lomperik: true,
  loser: true,
  luibak: true,
  luibuis: true,
  luiskop: true,
  lul: true,
  lulhannes: true,
  lummel: true,
  maaghoer: true,
  macaronivreter: true,
  mafkaas: true,
  mafkees: true,
  mafketel: true,
  mafkikker: true,
  mafklapper: true,
  makako: true,
  masque: true,
  matje: true,
  mietje: true,
  mikrodebiel: true,
  modderduivel: true,
  mof: true,
  moffenhoer: true,
  Moffrika: true,
  mongool: true,
  morsebel: true,
  mosselhoer: true,
  naadschurftsmurfigebeftekkel: true,
  nanoneuker: true,
  nasivreter: true,
  natkut: true,
  nazi: true,
  neger: true,
  negerin: true,
  negers: true,
  netenvreter: true,
  neukpaal: true,
  nikker: true,
  nikkerin: true,
  nikkerins: true,
  nikkers: true,
  nitwit: true,
  noppeshoer: true,
  oelepoeper: true,
  oesterkut: true,
  oetlul: true,
  okselhaas: true,
  okselharenbillereetzweetzuur: true,
  okselschimmelhoer: true,
  olalul: true,
  olifantenlul: true,
  olifantenschimmelslurf: true,
  olijfneuker: true,
  onderkruipsel: true,
  optyfen: true,
  ossenkop: true,
  ossenlul: true,
  ouwehoedendoos: true,
  paardebeffer: true,
  paardekloot: true,
  paardekut: true,
  paardelid: true,
  paardelul: true,
  paardelullo: true,
  paardenanus: true,
  paardenlul: true,
  paardenneuker: true,
  paardenpenis: true,
  paardenpenislikker: true,
  paardenpijper: true,
  paardenpikkenpoetser: true,
  paardenpikkerslikker: true,
  paardenplasserwasser: true,
  paarsedruiveneikel: true,
  paashaasneuker: true,
  paashaasschaamhaarverzamelaar: true,
  palmboomneuker: true,
  pandapikkenpijper: true,
  pantoffelneuker: true,
  pantyneuker: true,
  papagaaiekuttenbefkonijn: true,
  paplap: true,
  papomslag: true,
  papzak: true,
  parasiet: true,
  parelridder: true,
  parkeerkut: true,
  parkiet: true,
  parkinghomo: true,
  parkschijter: true,
  pasjakroet: true,
  passoatrut: true,
  patjakker: true,
  patjepeeër: true,
  patser: true,
  pedo: true,
  pedocyclomasturbant: true,
  pedomaan: true,
  pedomeester: true,
  pedopenis: true,
  pedopyrofilatomaan: true,
  pedoviel: true,
  pekelhoer: true,
  pekelteef: true,
  penishoofd: true,
  peniskoker: true,
  peniskokerlikker: true,
  penisleir: true,
  penislijder: true,
  penislikker: true,
  penispijper: true,
  penispukkelpuist: true,
  penisspuiter: true,
  penisworstelaar: true,
  peniszuiger: true,
  pennelikker: true,
  pensklepper: true,
  pepermuntvreter: true,
  perebek: true,
  perenplukker: true,
  pestbek: true,
  pestboer: true,
  pestlijder: true,
  peukenneuker: true,
  pezewever: true,
  pielemans: true,
  pielesnuiver: true,
  piemel: true,
  piemelaar: true,
  piemelboxer: true,
  piemelbrie: true,
  piemelgymmer: true,
  piemelpatser: true,
  piemelpiraat: true,
  piemelpuist: true,
  piemelpunt: true,
  piemelteef: true,
  piemelwijf: true,
  pierewipper: true,
  pietlut: true,
  piezer: true,
  piggel: true,
  piggelmee: true,
  pigmentvreter: true,
  pijpbek: true,
  pijpdeuramateur: true,
  pijpelullebakkes: true,
  pijpendemonkyboy: true,
  pijpenkoplikker: true,
  pijpert: true,
  pijpgeit: true,
  pijphoer: true,
  pijphomo: true,
  pijpmond: true,
  pijpmonk: true,
  pijppiloot: true,
  pijpslet: true,
  pijpzuiger: true,
  pik: true,
  pikhaar: true,
  pinda: true,
  pindachinees: true,
  pindapoepchinees: true,
  pleurislijder: true,
  ploert: true,
  plucheplakker: true,
  plurk: true,
  pokkenlijder: true,
  pokkenwijf: true,
  polak: true,
  politiemuts: true,
  pommel: true,
  populist: true,
  potgerukte: true,
  pothoer: true,
  puistenkop: true,
  pukkelbek: true,
  pummel: true,
  quikhoer: true,
  quikteef: true,
  raamneger: true,
  racechinees: true,
  racesjinees: true,
  ragkut: true,
  ragnicht: true,
  railnicht: true,
  raketpiraat: true,
  ramenlikker: true,
  rammelhoofd: true,
  rampdebiel: true,
  rampenbek: true,
  randdebiel: true,
  ransaap: true,
  rapenschijter: true,
  reetkever: true,
  reetridder: true,
  reetroeier: true,
  reetveger: true,
  rekel: true,
  retenlikker: true,
  roetmop: true,
  rotmof: true,
  rotzak: true,
  rugridder: true,
  ruigpoot: true,
  sabberkut: true,
  sambalvreter: true,
  satan: true,
  satéboer: true,
  satehoer: true,
  satraap: true,
  schaamhaaretendebaffer: true,
  schaamhaarverzamelaar: true,
  schaamlikkerkip: true,
  schaamlipgezwel: true,
  schaamliplikker: true,
  schaamluis: true,
  schaamluisbedrieger: true,
  schaampik: true,
  schadewijf: true,
  schapenneuker: true,
  schijtluis: true,
  schimmelkut: true,
  schoft: true,
  schurftkop: true,
  schurk: true,
  schuurmeid: true,
  secreet: true,
  sekreet: true,
  slet: true,
  slettebak: true,
  slettenbak: true,
  slijmerd: true,
  sloerie: true,
  smeerkanis: true,
  smeerlap: true,
  smeerpijp: true,
  smiecht: true,
  sodeflikker: true,
  soepkip: true,
  spaghettivreter: true,
  spast: true,
  specht: true,
  spleetoog: true,
  steenezel: true,
  stinkbok: true,
  stinker: true,
  stinkerd: true,
  stoephoer: true,
  stomkop: true,
  strontzak: true,
  taalnazi: true,
  taart: true,
  taboetstabiske: true,
  takenboeker: true,
  takkehoer: true,
  takkenteef: true,
  takkentrut: true,
  takketrol: true,
  takketut: true,
  tamponkut: true,
  tampontoerist: true,
  tampontrekker: true,
  tangelteef: true,
  teef: true,
  teringlijder: true,
  teringlijer: true,
  tietvlieg: true,
  totebel: true,
  troelala: true,
  trut: true,
  tyfuslijder: true,
  uienreet: true,
  uiersnuiver: true,
  uilskuiken: true,
  uitgekotstekamelenkut: true,
  vagina: true,
  vaginalehangflapper: true,
  vaginavarken: true,
  vaginavochtabsorbeerder: true,
  variétéhoer: true,
  varkenshol: true,
  varkenskop: true,
  vaselinevriend: true,
  vetklep: true,
  vetzak: true,
  viruswappie: true,
  vlooienzak: true,
  vuilak: true,
  vullis: true,
  waailap: true,
  wabipi: true,
  wafelijzer: true,
  wanhopigedarmrukker: true,
  wannabee: true,
  wasknijper: true,
  waterdrinker: true,
  waterhoofd: true,
  watervlo: true,
  wipkip: true,
  xylofoonneuker: true,
  yoghurtpisser: true,
  yoghurtventje: true,
  yogoteef: true,
  yoyolul: true,
  zaadheks: true,
  zaadhoer: true,
  zaadnaad: true,
  zaadschieter: true,
  zaadslet: true,
  zaadslikker: true,
  zaadsnuiver: true,
  zaadspuitende: true,
  zaadstaafteler: true,
  zaadvrager: true,
  zaadvreter: true,
  zaadzwerver: true,
  zakkendroller: true,
  zakkenvuller: true,
  zakkenwasser: true,
  zanikert: true,
  zatmuil: true,
  zattekop: true,
  zeekbakkes: true,
  zelfmord: true,
  zelfmoord: true,
  "zelf moord": true,
  suicide: true,
  kms: true,
  kys: true,
  zeekbos: true,
  zeikbeer: true,
  zeikhannes: true,
  zeiklijster: true,
  zeiksnor: true,
  zeikstraal: true,
  zwartjan: true,
  zwartjoekel: true,
  zwijn: true,
  zwijnjak: true,
  hijack: true,
  "hi-jack": true,
  bombings: true,
  bombing: true,
  overval: true,
  aanval: true,
  roofoverval: true,
  rooftocht: true,
  plunder: true,
  plunderen: true,
  ontvoering: true,
  kapen: true,
  kaping: true,
  diefstal: true,
  invasie: true,
  roven: true,
  aanrijden: true,
  omverrijden: true,
  overrijden: true,
  aanrijding: true,
  vluchtauto: true,
};
