import { useState } from "react";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

export const isTouch: () => boolean = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  (navigator.hasOwnProperty("msMaxTouchPoints") &&
    // @ts-ignore
    navigator.msMaxTouchPoints > 0);

// note: always use 'afterHydration' if you are using it to 'change' your DOM,
// as it will otherwise result in a mismatch of your DOM on the client and server-side ( SSR hydration error)
export function useIsTouchDevice(afterHydration: boolean = true): boolean {
  const [isTouchDevice, setIsTouchDevice] = useState(
    !afterHydration ? isTouch() : false
  );

  useIsomorphicLayoutEffect(() => {
    if (afterHydration) {
      setIsTouchDevice(isTouch());
    }
  }, [afterHydration]);

  return isTouchDevice;
}
