import classNames from "classnames";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import styles from "./Answer.module.scss";

type AnswerProps = {
  active: boolean;
  text?: string;
  onTextRevealed?: (ref?: React.RefObject<HTMLParagraphElement>) => void;
};

const letterRevealDuration = 0.19;
const amountOfLetters = 4;
const possibleCharacters = "abcdefghijklmnopqrstuvwxyz#%&^+=-";
function randomLetter() {
  const num = Math.floor(possibleCharacters.length * Math.random());
  return possibleCharacters.charAt(num);
}

const revealWithEffect = false;

export default function Answer({ active, text, onTextRevealed }: AnswerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const answerRef = useRef<HTMLParagraphElement>(null);
  const alreadyAnswerRef = useRef<HTMLParagraphElement>(null);
  const previousLetterIndexRef = useRef<number>(0);

  useEffect(() => {
    if (ref.current) {
      if (active) {
        gsap
          .timeline()
          .set(ref.current, {
            height: "auto",
          })
          .fromTo(
            ref.current,
            {
              opacity: 0,
              y: 15,
            },
            {
              opacity: 1,
              y: 0,
              duration: 0.35,
              ease: "Power1.easeOut",
            }
          );
      } else {
        gsap
          .timeline()
          .fromTo(
            ref.current,
            {
              opacity: 1,
              y: 0,
            },
            {
              opacity: 0,
              y: -15,
              duration: 0.35,
              ease: "Power1.easeOut",
            }
          )
          .to(ref.current, {
            height: 0,
          });
      }
    }
  }, [active]);

  useEffect(() => {
    if (text !== undefined && alreadyAnswerRef.current) {
      alreadyAnswerRef.current.innerHTML = "";

      const revealTl = gsap.timeline({
        onComplete: () => {
          onTextRevealed?.(alreadyAnswerRef);
        },
      });

      if (revealWithEffect) {
        const letters = text
          .replace(/(\d+)\.(\d+)/gi, "$1⋅$2")
          .replace(/(\d+)\.(\d+)/gi, "$1⋅$2") // Run it twice for numbers like 100.000.000
          .split("");
        previousLetterIndexRef.current = 0;
        letters.forEach((letter, index) => {
          if (alreadyAnswerRef.current) {
            alreadyAnswerRef.current.innerHTML = `${alreadyAnswerRef.current.innerHTML}<span id="letter-${index}"></span>`;
            const letterSequence = [
              letter === "." ? "." : randomLetter(),
              letter === "." ? "." : randomLetter(),
              letter === "." ? "." : randomLetter(),
              letter === "." ? "." : randomLetter(),
              letter,
            ];
            letterSequence.forEach((revealLetter) => {
              revealLetter = revealLetter.replace("⋅", ".");
              revealTl.call(
                () => {
                  if (alreadyAnswerRef.current) {
                    const el = alreadyAnswerRef.current.querySelector(
                      `#letter-${index}`
                    );
                    if (el) el.innerHTML = revealLetter;
                  }
                },
                [],
                previousLetterIndexRef.current !== index
                  ? `<-=${letterRevealDuration * 0.75}`
                  : `<+=${
                      letter === "." || letter === ","
                        ? letter === "."
                          ? letterRevealDuration * 2.0
                          : letterRevealDuration * 1.0
                        : letterRevealDuration / amountOfLetters
                    }`
              );
              previousLetterIndexRef.current = index;
            });
          }
        });
      } else {
        const letters = text.split("");
        letters.forEach((letter, index) => {
          revealTl.call(
            () => {
              if (alreadyAnswerRef.current)
                alreadyAnswerRef.current.innerHTML = `${alreadyAnswerRef.current.innerHTML}${letter}`;
            },
            [],
            `>+=${
              letter === "." ||
              letter === "," ||
              letter === "!" ||
              letter === "?"
                ? letter === "." || letter === "!" || letter === "?"
                  ? letterRevealDuration * 4
                  : letterRevealDuration * 1.8
                : letterRevealDuration * 0.24
            }`
          );
        });
      }

      return () => {
        revealTl.kill();
      };
      // words.forEach((word, i) => {
      //   gsap.to(answerRef.current, {
      //     duration: 0.25,
      //     delay: 0.25 * i + 0.001,
      //     onStart: () => {
      //       alreadyAnswerRef.current.innerHTML = words.slice(0, i).join(" ");
      //     },
      //     scrambleText: {
      //       tweenLength: false,
      //       text: `${word} `,
      //       chars: "lowercase",
      //     },
      //   });
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <div className={classNames(styles.answer)} ref={ref}>
      <div>
        <p className={styles.answerText}>
          <span ref={alreadyAnswerRef}></span>
          <span ref={answerRef}></span>
        </p>
      </div>
    </div>
  );
}
