import React, { useRef } from "react";
import { useGLTF, useHelper } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Mesh, SpotLight, SpotLightHelper, MeshStandardMaterial } from "three";
import { useIsDebug } from "../../../../../context/garageContext";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder031: Mesh;
    Cylinder031_1: Mesh;
    Cylinder031_2: Mesh;
    Cylinder031_3: Mesh;
  };
  materials: {
    ["Lamp Base"]: MeshStandardMaterial;
    ["Lamp Cover"]: MeshStandardMaterial;
    ["Lamp Inside"]: MeshStandardMaterial;
    ["Lamp Light"]: MeshStandardMaterial;
  };
};

export default function Lamp(
  props: JSX.IntrinsicElements["group"] & {
    position: [x: number, y: number, z: number];
  }
) {
  const { nodes, materials } = useGLTF(
    "/assets/models/lamp.glb"
  ) as unknown as GLTFResult;
  const spotLightRef = useRef<SpotLight>(null);
  const lightBulbRef = useRef<Mesh>(null);
  const debug = useIsDebug();
  // @ts-ignore
  useHelper(debug && spotLightRef, SpotLightHelper, "blue");

  return (
    <>
      <group {...props} scale={0.3}>
        <group name="Table_Lamp" position={[0, 0, 0]}>
          <mesh
            name="Cylinder031"
            geometry={nodes.Cylinder031.geometry}
            material={materials["Lamp Base"]}
            castShadow
          />
          <mesh
            name="Cylinder031_1"
            geometry={nodes.Cylinder031_1.geometry}
            material={materials["Lamp Cover"]}
            castShadow
          />
          <mesh
            name="Cylinder031_2"
            geometry={nodes.Cylinder031_2.geometry}
            // material={materials["Lamp Inside"]}
            // material-side={DoubleSide}
            castShadow
          >
            <meshStandardMaterial
              color="orange"
              emissiveIntensity={1.5}
              emissive="orange"
            />
          </mesh>
          <mesh
            name="Cylinder031_3"
            geometry={nodes.Cylinder031_3.geometry}
            // material-color="blue"
            // material-fog={false}
            ref={lightBulbRef}
          >
            <meshStandardMaterial
              color="orange"
              emissiveIntensity={1.5}
              emissive="orange"
            />
          </mesh>
        </group>
      </group>
    </>
  );
}

useGLTF.preload("/assets/models/lamp.glb");
