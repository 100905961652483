import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder024: Mesh;
    Cylinder024_1: Mesh;
    Cylinder024_2: Mesh;
    Cylinder024_3: Mesh;
  };
  materials: {
    ["Chair Mesh"]: MeshStandardMaterial;
    ["Chair Base"]: MeshStandardMaterial;
    ["Chair Base Black"]: MeshStandardMaterial;
  };
};

export default function Chair(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/fancy_chair.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.32}>
      <group
        name="Cylinder002"
        rotation={[Math.PI / 2, -1.46, -Math.PI / 2]}
        scale={[0.63, 0.67, 0.63]}
      >
        <mesh
          name="Cylinder024"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder024.geometry}
          material={materials["Chair Mesh"]}
        />
        <mesh
          name="Cylinder024_1"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder024_1.geometry}
          material={materials["Chair Base"]}
        />
        <mesh
          name="Cylinder024_2"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder024_2.geometry}
          material={materials["Chair Base Black"]}
        />
        <mesh
          name="Cylinder024_3"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder024_3.geometry}
          material={materials["Chair Mesh"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/fancy_chair.glb");
