import {
  BakeShadows,
  PerformanceMonitor,
  ScrollControls,
} from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { BoxL } from "./objects/boxes/BoxL";
import { BoxLLight } from "./objects/boxes/BoxLLight";
import { BoxM } from "./objects/boxes/BoxM";
import { BoxMLight } from "./objects/boxes/BoxMLight";
import Cabinet from "./objects/cabinet/Cabinet";
import Camera from "./objects/camera/Camera";
import Ceiling from "./objects/ceiling/Ceiling";
import Chair from "./objects/chair/Chair";
import Desk from "./objects/desk/Desk";
import Floor from "./objects/floor/Floor";
import DynamicFog from "./objects/fog/DynamicFog";
import FramedPoster from "./objects/framed-posters/FramedPoster";
import FramedPoster1 from "./objects/framed-posters/FramedPoster1";
import FramedPoster2 from "./objects/framed-posters/FramedPoster2";
import Lamp from "./objects/lamp/Lamp";
import Lights from "./objects/lights/Lights";
import PostIts from "./objects/post-its/PostIts";
import PosterHolderL from "./objects/poster-holders/PosterHolderL";
import PosterHolderM from "./objects/poster-holders/PosterHolderM";
import A3Poster1 from "./objects/posters/A3_Poster_1";
import A3Poster2 from "./objects/posters/A3_Poster_2";
import A3Poster3 from "./objects/posters/A3_Poster_3";
import A4Poster1 from "./objects/posters/A4_Poster_1";
import A4Poster2 from "./objects/posters/A4_Poster_2";
import Scribbles from "./objects/scribbles/Scribbles";
import ShelfWithBoxes from "./objects/shelf-with-boxes/ShelfWithBoxes";
import Stool from "./objects/stool/Stool";
import Texts, { texts } from "./objects/texts/Texts";
import Walls from "./objects/walls/Walls";
import { useGarageContext } from "../../../context/garageContext";
import Effects from "./objects/effects/Effects";
import {
  Group,
  PlaneGeometry,
  Mesh,
  MeshStandardMaterial,
  PerspectiveCamera,
  SpotLight,
  AmbientLight,
  MeshBasicMaterial,
  Fog,
  BoxGeometry,
} from "three";
import { extend } from "@react-three/fiber";

extend({
  Group,
  PlaneGeometry,
  Mesh,
  MeshStandardMaterial,
  PerspectiveCamera,
  SpotLight,
  AmbientLight,
  MeshBasicMaterial,
  Fog,
  BoxGeometry,
});

export default function IntroScene({
  onSceneComplete,
  onIntroAnimationComplete,
  onStartScrolling,
  start,
}: {
  onSceneComplete: () => void;
  onIntroAnimationComplete: () => void;
  onStartScrolling: () => void;
  start?: boolean;
}) {
  const { screenSizeOffset } = useGarageContext();
  const color = "#1d0000";
  const fogStart = 0;
  const fogEnd = 15;
  // const { color, fogStart, fogEnd } = useControls(
  //   "Background",
  //   {
  //     color: "#1d0000",
  //     fogStart: { value: 0, min: 0, max: 21 },
  //     fogEnd: { value: 15, min: 0, max: 22 },
  //   },
  //   {
  //     collapsed: true,
  //   }
  // );
  const [enableScrolling, setEnableScrolling] = useState(false);

  const { gl } = useThree();

  useEffect(() => {
    gl.setClearColor(color, 1);
  }, [color, gl]);

  useEffect(() => {
    window.scrollTo({
      top: 700,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  function handleCameraAnimationComplete() {
    setEnableScrolling(true);
    onIntroAnimationComplete();
  }

  return (
    <>
      {/* LEFT SIDE */}
      <group position-x={screenSizeOffset}>
        <FramedPoster2
          rotation-y={Math.PI * 0.5}
          position={[-7.4, 3.8, -1.5]}
        />
        <group name="box-set1" position={[-6.6, 0, -3.5]}>
          <BoxL rotation-y={Math.PI * 0.5} />
          <BoxMLight position={[0, 0.66, 0]} rotation-y={Math.PI * 0.4} />
          <BoxLLight rotation-y={Math.PI * 0.5} position={[0, 0, 1]} />
          <BoxLLight rotation-y={Math.PI * 0.5} position={[0, 0.66, 1]} />
        </group>
        <group name="desk" position={[-6.2, 0, -7.5]}>
          <Desk rotation-y={Math.PI * 0.5} />
          <Lamp position={[-0.2, 1.4, -1.3]} rotation-y={Math.PI * 0.65} />
          <PostIts position={[-0.2, 1.4, -0.65]} rotation-y={Math.PI * 0.1} />
          <Chair position={[2, 0, 0]} rotation-y={Math.PI * 1.15} />
        </group>
        <group name="poster-holders" position={[-7, 0, -14.15]}>
          <PosterHolderL
            rotation-z={Math.PI * 0.04}
            rotation-x={Math.PI * -0.04}
          />
          {/*<PosterHolderL position={[0, 0, 0.25]} rotation-z={Math.PI * 0.04} />*/}
          <PosterHolderM
            position={[0.3, 0, 0]}
            rotation-z={Math.PI * 0.07}
            rotation-x={Math.PI * -0.06}
          />
        </group>
        <FramedPoster rotation-y={Math.PI * 0.5} position={[-7.4, 3.5, -13]} />
        <group name="desk-posters">
          <A3Poster1
            rotation-z={Math.PI * -0.5}
            rotation-x={Math.PI * 0.5}
            position={[-7.3999, 2.7, -3.2]}
          />
          <A3Poster2
            rotation-z={Math.PI * -0.5}
            rotation-x={Math.PI * 0.5}
            position={[-7.3999, 2.4, -8]}
          />
          <A3Poster3
            rotation-z={Math.PI * -0.5}
            rotation-x={Math.PI * 0.5}
            position={[-7.3999, 3.5, -8]}
          />
          <A4Poster1
            rotation-z={Math.PI * -0.5}
            rotation-x={Math.PI * 0.48}
            position={[-7.3999, 2.8, -7]}
          />
          <A4Poster2
            rotation-z={Math.PI * -0.5}
            rotation-x={Math.PI * 0.5}
            position={[-7.3999, 2.1, -7]}
          />
        </group>
        <group
          name="box-set3"
          position={[-6.45, 0, -17.1]}
          rotation-y={Math.PI * -0.5}
        >
          <BoxMLight position={[0, 1.31, -0]} />
          <BoxLLight position={[0, 0.65, -0]} />
          <BoxLLight position={[0, 0, -0.15]} />
        </group>
        <group name="self-with-boxes" position={[-6.6, 0, -19.75]}>
          <ShelfWithBoxes rotation-y={Math.PI * 0.5} />
          {/* first layer*/}
          <BoxLLight position={[0, 0.37, 1]} rotation-y={Math.PI * 0.5} />
          <BoxLLight position={[0, 0.37, 0]} rotation-y={Math.PI * 0.5} />
          <BoxM position={[0.2, 1.04, 0.45]} rotation-y={Math.PI} />
          {/* second layer*/}
          <BoxLLight position={[0, 1.58, 1]} rotation-y={Math.PI * 0.5} />
          <BoxLLight position={[0, 1.58, -0.35]} rotation-y={Math.PI * 0.4} />
          {/* third layer*/}
          <BoxLLight position={[0, 2.79, 1]} rotation-y={Math.PI * 0.5} />
          <BoxMLight position={[0, 2.79, 0]} rotation-y={Math.PI * 0.5} />
          <BoxMLight position={[0, 2.79, -0.65]} rotation-y={Math.PI * 0.5} />
          {/* fourth layer*/}
          <BoxMLight position={[0, 4, 1.25]} rotation-y={Math.PI * 0.5} />
          <BoxMLight position={[0, 4.4, 1.25]} rotation-y={Math.PI * 0.5} />
          <BoxM position={[0, 4, 0.65]} rotation-y={Math.PI * 0.5} />
          <BoxMLight position={[0, 4.4, 0.65]} rotation-y={Math.PI * 0.5} />
          <BoxLLight position={[0, 4, -0.75]} rotation-y={Math.PI * 0.5} />
          {/* fifth layer*/}
          <BoxLLight position={[0, 5.21, 0.2]} rotation-y={Math.PI * 0.5} />
          <BoxLLight position={[0, 5.21, -1]} rotation-y={Math.PI * 0.5} />
        </group>
        <Cabinet position={[-6.3, 0, -10.5]} rotation-y={Math.PI * 0.5} />
        <FramedPoster1
          rotation-y={Math.PI * 0.5}
          position={[-7.4, 3.5, -27.5]}
        />
      </group>

      {/*RIGHT SIDE */}
      <group position-x={-screenSizeOffset}>
        <group name="box-set2" position={[6.45, 0, 0]}>
          <BoxMLight position={[0, 1.31, -0]} rotation-y={Math.PI * -0.5} />
          <BoxLLight position={[0, 0.65, -0]} rotation-y={Math.PI * -0.5} />
          <BoxLLight rotation-y={Math.PI * -0.5} />
          <BoxLLight position={[0, 0.65, 1]} rotation-y={Math.PI * -0.5} />
          <BoxLLight position={[0, 0, 1]} rotation-y={Math.PI * -0.5} />
        </group>
        <FramedPoster1
          rotation-y={Math.PI * -0.5}
          position={[7.4, 3.8, -1.5]}
        />

        <group name="poster-holders" position={[7.1, 0, -4.15]}>
          <PosterHolderL
            rotation-z={Math.PI * -0.04}
            rotation-x={Math.PI * -0.04}
          />
          {/*<PosterHolderL position={[0, 0, 0.25]} rotation-z={Math.PI * 0.04} />*/}
          <PosterHolderM
            position={[-0.3, 0, -0.06]}
            rotation-z={Math.PI * -0.07}
            rotation-x={Math.PI * -0.04}
          />
        </group>
        <group name="desk-posters2" position={[7.484, 3, -5.3]}>
          <A3Poster2
            rotation-z={Math.PI * 0.5}
            rotation-x={Math.PI * 0.5}
            position={[0, 0.5, -6]}
          />
          <A3Poster3
            rotation-z={Math.PI * 0.5}
            rotation-x={Math.PI * 0.5}
            position={[0, 0.7, -1.8]}
          />
          <A4Poster1
            rotation-z={Math.PI * 0.5}
            rotation-x={Math.PI * 0.48}
            position={[0, 0.1, -1]}
          />
        </group>
        <group name="box-set2" position={[6.45, 0, -11.1]}>
          <BoxMLight position={[0, 1.31, 0.5]} rotation-y={Math.PI * -0.5} />
          <BoxLLight position={[0, 0.65, -0]} rotation-y={Math.PI * -0.5} />
          <BoxLLight rotation-y={Math.PI * -0.5} />
          <BoxLLight position={[0, 0.65, 1]} rotation-y={Math.PI * -0.5} />
          <BoxLLight position={[0, 0, 1]} rotation-y={Math.PI * -0.5} />
        </group>
        <group name="box-set4" position={[6.45, 0, -18.2]}>
          <BoxL position={[0, 0.65, -0.5]} rotation-y={Math.PI * 0.5} />
          <BoxL rotation-y={Math.PI * 0.5} />
          <BoxLLight position={[0, 0, -1]} rotation-y={Math.PI * 0.5} />
        </group>
        <group name="desk-posters3" position={[7.486, 3.2, -17.1]}>
          <A3Poster2
            rotation-z={Math.PI * 0.5}
            rotation-x={Math.PI * 0.5}
            position={[0, -0.1, -0.8]}
          />
          <A3Poster3
            rotation-z={Math.PI * 0.5}
            rotation-x={Math.PI * 0.48}
            position={[0, -0.8, 0]}
          />
          <A4Poster2 rotation-z={Math.PI * 0.5} rotation-x={Math.PI * 0.5} />
        </group>
        <FramedPoster2 rotation-y={Math.PI * -0.5} position={[7.4, 3.8, -21]} />

        <group name="desk-posters3" position={[7.486, 3, -23.3]}>
          <A3Poster1
            rotation-z={Math.PI * 0.5}
            rotation-x={Math.PI * 0.5}
            position={[0, 0, 0]}
          />
        </group>
        <group name="desk2" position={[6.4, 0, -27.75]}>
          <Desk rotation-y={Math.PI * -0.5} />
          <Lamp position={[0.2, 1.4, 1.3]} rotation-y={Math.PI * -0.15} />
        </group>
        <Stool position={[5.4, 0, -27.75]} />
      </group>

      <Floor />
      <Walls />
      <Ceiling />

      <ScrollControls pages={texts.length * 3} enabled={enableScrolling}>
        {/* <fog attach="fog" args={[color, fogStart, fogFarRef.current]} /> */}
        <DynamicFog color={color} fogStart={fogStart} fogEnd={fogEnd} />
        <Texts onComplete={onSceneComplete} start={start} />
        <Scribbles />

        <Lights />
        <Camera
          start={start}
          onIntroAnimationComplete={handleCameraAnimationComplete}
          allowScrolling={enableScrolling}
          onStartScrolling={onStartScrolling}
        />
      </ScrollControls>
      <BakeShadows />
      <PerformanceMonitor>
        <Effects />
      </PerformanceMonitor>
    </>
  );
}
