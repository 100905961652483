import ReactHowler from "react-howler";
import { useGarageContext } from "../../../context/garageContext";
import { useEffect, useRef } from "react";

type BgAudioProps = {
  playing: boolean;
};

export default function BgAudio({ playing }: BgAudioProps) {
  const audioRef = useRef<ReactHowler>(null);
  const { muted } = useGarageContext();
  const oldPlaying = useRef(playing);

  useEffect(() => {
    if (audioRef.current) {
      if (playing && playing !== oldPlaying.current) audioRef.current.seek(2.5);
      oldPlaying.current = playing;
    }
  }, [playing]);

  return (
    <ReactHowler
      ref={audioRef}
      src="/assets/audio/bg_music.mp3"
      loop
      playing={playing}
      mute={muted}
      volume={0.05}
    />
  );
}
