import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube026: Mesh;
    Cube026_1: Mesh;
    Cube026_2: Mesh;
  };
  materials: {
    Passpartout: MeshStandardMaterial;
    ["A3 Poster 3_2"]: MeshStandardMaterial;
    ["Wood Frame"]: MeshStandardMaterial;
  };
};

export default function FramedPoster2(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Framed_Poster_2.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props}>
      <group name="Framed_Poster_2" scale={0.8}>
        <mesh
          name="Cube026"
          castShadow
          receiveShadow
          geometry={nodes.Cube026.geometry}
          material={materials.Passpartout}
        />
        <mesh
          name="Cube026_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube026_1.geometry}
          material={materials["A3 Poster 3_2"]}
        />
        <mesh
          name="Cube026_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube026_2.geometry}
          material={materials["Wood Frame"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/Framed_Poster_2.glb");
