import { forwardRef, useEffect, useRef } from "react";
import { SpotLight as SpotLightImpl } from "three";
import NeonLights from "./NeonLights";
import { useGarageContext } from "../../../../../context/garageContext";
import CustomSpotlight from "./CustomSpotlight";
import { useFrame } from "@react-three/fiber";
import { useScroll } from "@react-three/drei";

const Lights = forwardRef((props, ref) => {
  const { screenSizeOffset } = useGarageContext();
  const ambientLightColor = "#f2c7b8";
  const ambientLightIntensity = 0.2;
  const distantLightColor = "#ff0000";
  const distantLightDecay = 2.5;
  const distantLightDistance = 50;
  const distantLightPenumbra = 0.6;
  const distantLightAngle = 1.57;
  const distantLightAnglePower = 1.9;
  const distantLightAttenuation = 1.0;
  const ceilingLightsColor = "#ff6161";
  const ceilingLightsIntensity = 1.3;
  const ceilingLightsDecay = 1.7;
  const ceilingLightsDistance = 13;
  const ceilingLightsPenumbra = 0.08;
  const ceilingLightsAngle = 1.18;
  const ceilingLightsAnglePower = 0;
  const ceilingLightsAttenuation = 0;
  const deskLightsColor = "#f58e2e";
  const deskLightsIntensity = 4.4;
  const deskLightsDecay = 0.9;
  const deskLightsDistance = 10.5;
  const deskLightsPenumbra = 0.6;
  const deskLightsAngle = 1.31;
  const deskLightsAnglePower = 1.9;
  const deskLightsAttenuation = 1.0;

  const scroll = useScroll();

  const distantLightRef = useRef<SpotLightImpl>(null);
  const distantLightRef2 = useRef<SpotLightImpl>(null);
  const distantLightRef3 = useRef<SpotLightImpl>(null);

  const ceilingLight1Ref = useRef<SpotLightImpl>(null);
  const ceilingLight2Ref = useRef<SpotLightImpl>(null);
  const ceilingLight3Ref = useRef<SpotLightImpl>(null);
  const ceilingLight4Ref = useRef<SpotLightImpl>(null);
  const deskLight1Ref = useRef<SpotLightImpl>(null);
  const deskLight2Ref = useRef<SpotLightImpl>(null);

  useEffect(() => {
    distantLightRef.current?.target.updateMatrixWorld();
    distantLightRef2.current?.target.updateMatrixWorld();
    distantLightRef3.current?.target.updateMatrixWorld();
    ceilingLight1Ref.current?.target.updateMatrixWorld();
    ceilingLight2Ref.current?.target.updateMatrixWorld();
    ceilingLight3Ref.current?.target.updateMatrixWorld();
    ceilingLight4Ref.current?.target.updateMatrixWorld();
    deskLight1Ref.current?.target.updateMatrixWorld();
    deskLight2Ref.current?.target.updateMatrixWorld();
  }, []);

  //UPDATE DISTANCE LIGHT WITH USEFRAME
  useFrame(() => {
    let newIntensity = scroll.offset * scroll.offset - 0.25;
    let newIntensity2 = scroll.offset * scroll.offset - 0.1;

    if (newIntensity < 0) newIntensity = 0;
    if (
      distantLightRef.current &&
      distantLightRef2.current &&
      distantLightRef3.current
    ) {
      distantLightRef.current.intensity = newIntensity2;
      distantLightRef2.current.intensity = newIntensity;
      distantLightRef3.current.intensity = newIntensity;

      distantLightRef.current.position.x = -4 + scroll.offset * 10;
      distantLightRef2.current.position.x = -10 + scroll.offset * 10;
      distantLightRef3.current.position.x = -20 + (1 - scroll.offset) * 10;

      distantLightRef.current.target.updateMatrixWorld();
      distantLightRef2.current.target.updateMatrixWorld();
      distantLightRef3.current.target.updateMatrixWorld();

      // deskLight1Ref.current.decay = 0;
      // deskLight1Ref.current.attenuation = 1
    }
  });
  return (
    <>
      <ambientLight
        color={ambientLightColor}
        intensity={ambientLightIntensity}
      />

      {/* Distant light */}
      <CustomSpotlight
        ref={distantLightRef}
        position={[0, 1, -50]}
        target-position={[10, 5, 10]}
        color={distantLightColor}
        decay={distantLightDecay * 1.5}
        distance={distantLightDistance}
        penumbra={distantLightPenumbra}
        angle={distantLightAngle}
        attenuation={distantLightAttenuation}
        anglePower={distantLightAnglePower}
      />
      <CustomSpotlight
        ref={distantLightRef2}
        position={[0, 15, -45]}
        target-position={[0, 3, 0]}
        color={distantLightColor}
        decay={distantLightDecay * 1.7}
        distance={distantLightDistance}
        penumbra={distantLightPenumbra}
        angle={distantLightAngle}
        attenuation={distantLightAttenuation}
        anglePower={distantLightAnglePower}
      />
      <CustomSpotlight
        ref={distantLightRef3}
        position={[0, 15, -50]}
        target-position={[0, 8, 0]}
        color={distantLightColor}
        decay={distantLightDecay}
        distance={distantLightDistance}
        penumbra={distantLightPenumbra}
        angle={distantLightAngle}
        attenuation={distantLightAttenuation}
        anglePower={distantLightAnglePower}
      />

      {/* Ceiling lights */}
      <CustomSpotlight
        ref={ceilingLight1Ref}
        color={ceilingLightsColor}
        intensity={ceilingLightsIntensity}
        decay={ceilingLightsDecay}
        position={[0, 8, -4.6]}
        target-position={[0, 0, -4.6]}
        penumbra={ceilingLightsPenumbra}
        angle={ceilingLightsAngle}
        distance={ceilingLightsDistance}
        shadow-bias={-0.003}
        attenuation={ceilingLightsAttenuation}
        anglePower={ceilingLightsAnglePower}
        castShadow
      />
      <CustomSpotlight
        ref={ceilingLight2Ref}
        color={ceilingLightsColor}
        intensity={ceilingLightsIntensity}
        position={[0, 8, -9.4]}
        target-position={[0, 0, -9.4]}
        penumbra={ceilingLightsPenumbra}
        angle={ceilingLightsAngle}
        distance={ceilingLightsDistance}
        shadow-bias={-0.003}
        attenuation={ceilingLightsAttenuation}
        anglePower={ceilingLightsAnglePower}
        castShadow
      />
      <CustomSpotlight
        ref={ceilingLight3Ref}
        color={ceilingLightsColor}
        intensity={ceilingLightsIntensity}
        position={[0, 8, -14.2]}
        target-position={[0, 0, -14.2]}
        penumbra={ceilingLightsPenumbra}
        angle={ceilingLightsAngle}
        distance={ceilingLightsDistance}
        shadow-bias={-0.003}
        attenuation={ceilingLightsAttenuation}
        anglePower={ceilingLightsAnglePower}
        castShadow
      />
      <CustomSpotlight
        ref={ceilingLight4Ref}
        color={ceilingLightsColor}
        intensity={ceilingLightsIntensity}
        position={[0, 8, -19]}
        target-position={[0, 0, -19]}
        penumbra={ceilingLightsPenumbra}
        angle={ceilingLightsAngle}
        distance={ceilingLightsDistance}
        shadow-bias={-0.003}
        attenuation={ceilingLightsAttenuation}
        anglePower={ceilingLightsAnglePower}
        castShadow
      />
      <NeonLights rotation-y={Math.PI * 0.5} position={[0, 6.3, -12]} />

      {/* Desk lights */}
      <CustomSpotlight
        ref={deskLight1Ref}
        color={deskLightsColor}
        intensity={deskLightsIntensity}
        decay={deskLightsDecay}
        distance={deskLightsDistance}
        position={[-6.2 + screenSizeOffset, 1.95, -8.4]}
        target-position={[-6.2 + screenSizeOffset, 0, -8.4]}
        penumbra={deskLightsPenumbra}
        angle={deskLightsAngle}
        attenuation={deskLightsAttenuation}
        anglePower={deskLightsAnglePower}
        castShadow
      />
      <CustomSpotlight
        ref={deskLight2Ref}
        color={deskLightsColor}
        intensity={deskLightsIntensity}
        decay={deskLightsDecay}
        distance={deskLightsDistance}
        position={[6.2 - screenSizeOffset, 1.9, -26.65]}
        target-position={[6.2 - screenSizeOffset, 0, -26.65]}
        penumbra={deskLightsPenumbra}
        angle={deskLightsAngle}
        attenuation={deskLightsAttenuation}
        anglePower={deskLightsAnglePower}
        castShadow
      />
    </>
  );
});

Lights.displayName = "Lights";
export default Lights;
