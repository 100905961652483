import { EffectComposer, Noise, Vignette } from "@react-three/postprocessing";

export default function Effects() {
  const enabled = true;
  const noise = 0.05;

  return (
    <EffectComposer
      enabled={enabled}
      // disableNormalPass={true}
      multisampling={0}
    >
      <Noise
        opacity={noise}
        premultiply={false} // enables or disables noise premultiplication
        blendFunction={12}
      />
      <Vignette />
    </EffectComposer>
  );
}
