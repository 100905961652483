export const ENGLISH_SWEAR_WORDS = {
  "4r5e": false,
  "5h1t": false,
  "5hit": false,
  a55: false,
  abortion: true,
  anal: true,
  anus: true,
  ar5e: false,
  arrse: false,
  arse: true,
  ass: true,
  "ass-fucker": true,
  asses: true,
  assfucker: false,
  assfukka: false,
  asshole: true,
  assholes: true,
  asswhole: false,
  a_s_s: false,
  "b!tch": false,
  b00bs: false,
  b17ch: false,
  b1tch: false,
  ballbag: true,
  balls: true,
  ballsack: false,
  bastard: true,
  beastial: false,
  beastiality: false,
  bellend: true,
  bestial: true,
  bestiality: true,
  "bi+ch": false,
  biatch: false,
  bitch: true,
  bitcher: false,
  bitchers: false,
  bitches: true,
  bitchin: false,
  bitching: true,
  bloody: true,
  "blow job": false,
  blowjob: true,
  blowjobs: false,
  boiolas: false,
  bollock: false,
  bollok: true,
  boner: false,
  boob: true,
  boobs: true,
  booobs: false,
  boooobs: false,
  booooobs: false,
  booooooobs: false,
  breasts: true,
  buceta: true,
  bugger: false,
  bum: true,
  "bunny fucker": false,
  butt: true,
  butthole: false,
  buttmuch: false,
  buttplug: false,
  c0ck: false,
  c0cksucker: false,
  "carpet muncher": true,
  cawk: false,
  chink: true,
  cipa: true,
  cl1t: false,
  clit: false,
  clitoris: true,
  clits: false,
  cnut: false,
  cock: true,
  "cock-sucker": true,
  cockface: false,
  cockhead: false,
  cockmunch: false,
  cockmuncher: false,
  cocks: true,
  cocksuck: false,
  cocksucked: false,
  cocksucker: false,
  cocksucking: false,
  cocksucks: false,
  cocksuka: false,
  cocksukka: false,
  cok: false,
  cokmuncher: false,
  coksucka: false,
  coon: true,
  cox: false,
  crap: true,
  cum: true,
  cummer: false,
  cumming: false,
  cums: false,
  cumshot: true,
  cunilingus: false,
  cunillingus: true,
  cunnilingus: false,
  cunt: true,
  cuntlick: false,
  cuntlicker: false,
  cuntlicking: false,
  cunts: false,
  cyalis: false,
  cyberfuc: false,
  cyberfuck: false,
  cyberfucked: false,
  cyberfucker: false,
  cyberfuckers: false,
  cyberfucking: false,
  d1ck: false,
  damn: true,
  dick: true,
  dickhead: false,
  dildo: true,
  dildos: true,
  dink: true,
  dinks: false,
  dirsa: false,
  dlck: false,
  "dog-fucker": true,
  doggin: false,
  dogging: false,
  donkeyribber: false,
  doosh: false,
  duche: true,
  dyke: true,
  ejaculate: true,
  ejaculated: true,
  ejaculates: true,
  ejaculating: true,
  ejaculatings: false,
  ejaculation: true,
  ejakulate: false,
  "f u c k": false,
  "f u c k e r": false,
  f4nny: false,
  fag: true,
  fagging: true,
  faggitt: false,
  faggot: true,
  faggs: false,
  fagot: true,
  fagots: true,
  fags: false,
  fanny: true,
  fannyflaps: false,
  fannyfucker: false,
  fanyy: false,
  fatass: false,
  fcuk: false,
  fcuker: false,
  fcuking: false,
  feck: false,
  fecker: false,
  felching: true,
  fellate: false,
  fellatio: true,
  fingerfuck: false,
  fingerfucked: false,
  fingerfucker: false,
  fingerfuckers: false,
  fingerfucking: false,
  fingerfucks: false,
  fistfuck: false,
  fistfucked: false,
  fistfucker: false,
  fistfuckers: false,
  fistfucking: false,
  fistfuckings: false,
  fistfucks: false,
  flange: true,
  fook: false,
  fooker: false,
  fuck: true,
  fucka: false,
  fucked: true,
  fucker: true,
  fuckers: true,
  fuckhead: false,
  fuckheads: false,
  fuckin: false,
  fucking: true,
  fuckings: true,
  fuckingshitmotherfucker: false,
  fuckme: false,
  fucks: true,
  fuckwhit: false,
  fuckwit: false,
  "fudge packer": true,
  fudgepacker: false,
  fuk: false,
  fuker: false,
  fukker: false,
  fukkin: false,
  fuks: false,
  fukwhit: false,
  fukwit: false,
  fux: false,
  fux0r: false,
  f_u_c_k: false,
  gangbang: false,
  gangbanged: false,
  gangbangs: false,
  gaylord: false,
  gaysex: false,
  goatse: false,
  "god-dam": false,
  "god-damned": true,
  goddamn: true,
  goddamned: false,
  hardcoresex: false,
  hell: true,
  hoar: false,
  hoare: false,
  hoer: false,
  homo: false,
  hore: true,
  horniest: false,
  horny: true,
  hotsex: false,
  "jack-off": false,
  jackoff: false,
  jap: false,
  "jerk-off": true,
  jew: true,
  jews: true,
  jism: false,
  jiz: false,
  jizm: false,
  jizz: false,
  kawk: false,
  knob: false,
  knobead: false,
  knobed: false,
  knobend: false,
  knobhead: false,
  knobjocky: false,
  knobjokey: false,
  kock: true,
  kondum: false,
  kondums: false,
  kum: false,
  kummer: false,
  kumming: false,
  kums: false,
  kunilingus: false,
  "l3i+ch": false,
  l3itch: false,
  labia: true,
  lust: true,
  lusting: true,
  m0f0: false,
  m0fo: false,
  m45terbate: false,
  ma5terb8: false,
  ma5terbate: false,
  masochist: true,
  "master-bate": false,
  masterb8: false,
  "masterbat*": false,
  masterbat3: false,
  masterbate: false,
  masterbation: false,
  masterbations: false,
  masturbate: true,
  "mo-fo": false,
  mof0: false,
  mofo: false,
  mothafuck: false,
  mothafucka: false,
  mothafuckas: false,
  mothafuckaz: false,
  mothafucked: false,
  mothafucker: false,
  mothafuckers: false,
  mothafuckin: false,
  mothafucking: false,
  mothafuckings: false,
  mothafucks: false,
  "mother fucker": true,
  motherfuck: false,
  motherfucked: false,
  motherfucker: false,
  motherfuckers: false,
  motherfuckin: false,
  motherfucking: false,
  motherfuckings: false,
  motherfuckka: false,
  motherfucks: false,
  muff: false,
  mutha: false,
  muthafecker: false,
  muthafuckker: false,
  muther: false,
  mutherfucker: false,
  n1gga: false,
  n1gger: false,
  nazi: true,
  nigg3r: false,
  nigg4h: false,
  nigga: false,
  niggah: false,
  niggas: false,
  niggaz: false,
  nigger: true,
  niggers: true,
  nob: false,
  "nob jokey": false,
  nobhead: false,
  nobjocky: false,
  nobjokey: false,
  numbnuts: false,
  nutsack: false,
  orgasim: true,
  orgasims: false,
  orgasm: true,
  orgasms: true,
  p0rn: false,
  pawn: false,
  pecker: true,
  penis: true,
  penisfucker: false,
  phonesex: false,
  phuck: false,
  phuk: false,
  phuked: false,
  phuking: false,
  phukked: false,
  phukking: false,
  phuks: false,
  phuq: false,
  pigfucker: false,
  pimpis: false,
  piss: true,
  pissed: true,
  pisser: true,
  pissers: false,
  pisses: true,
  pissflaps: false,
  pissin: false,
  pissing: true,
  pissoff: true,
  poop: true,
  porn: true,
  porno: true,
  pornography: true,
  pornos: false,
  prick: true,
  pricks: true,
  pron: false,
  pube: true,
  pusse: false,
  pussi: false,
  pussies: true,
  pussy: true,
  pussys: false,
  rape: true,
  rapist: true,
  rectum: true,
  retard: true,
  rimjaw: false,
  rimming: true,
  "s hit": false,
  "s.o.b.": false,
  sadist: true,
  schlong: false,
  screwing: true,
  scroat: false,
  scrote: false,
  scrotum: true,
  semen: true,
  sex: true,
  "sh!+": false,
  "sh!t": false,
  sh1t: false,
  shag: true,
  shagger: false,
  shaggin: false,
  shagging: true,
  shemale: true,
  "shi+": false,
  shit: true,
  shitdick: false,
  shite: true,
  shited: false,
  shitey: false,
  shitfuck: false,
  shitfull: false,
  shithead: false,
  shiting: false,
  shitings: false,
  shits: true,
  shitted: true,
  shitter: false,
  shitters: false,
  shitting: true,
  shittings: false,
  shitty: true,
  skank: true,
  slut: true,
  sluts: true,
  smegma: true,
  smut: true,
  snatch: true,
  "son-of-a-bitch": true,
  spac: true,
  spunk: true,
  s_h_i_t: false,
  t1tt1e5: false,
  t1tties: false,
  teets: false,
  teez: false,
  testical: false,
  testicle: true,
  tit: true,
  titfuck: false,
  tits: true,
  titt: true,
  tittie5: false,
  tittiefucker: false,
  titties: false,
  tittyfuck: false,
  tittywank: false,
  titwank: false,
  tosser: false,
  turd: true,
  tw4t: false,
  twat: false,
  twathead: false,
  twatty: false,
  twunt: false,
  twunter: false,
  v14gra: false,
  v1gra: false,
  vagina: true,
  viagra: true,
  vulva: true,
  w00se: false,
  wang: true,
  wank: true,
  wanker: false,
  wanky: false,
  whoar: false,
  whore: true,
  willies: false,
  willy: false,
  "x rated": true,
  xrated: false,
  xxx: true,
};
