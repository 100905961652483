export const PORSCHE_FAMILY = {
  "johanna kaes": true,
  "aloisia johanna": true,
  "aloisia kaes": true,
  "hedwig porsche": true,
  "louise porsche": true,
  "dorothea reitz": true,
  "wilhelmina porsche": true,
  "wilhelmine porsche": true,
  "ernst porsche": true,
  "anton porsche": true,
  "ferry porsche": true,
  ferdiporsche: true,
  "ferdi porsche": true,
  "ferdinand porsche": true,
  "ferdinan porsche": true,
  ferdinanporsche: true,
  ferdinandporsche: true,
  ferryporsche: true,
  feryporsche: true,
  "fery porsche": true,
  "alexander porsche": true,
  "gerhard porsche": true,
  "hanspeter porsche": true,
  "hans porsche": true,
  "peter porsche": true,
  "heinz porsche": true,
  "wolfgang porsche": true,
  "wolfgang heinz": true,
  "charlotte wanivenhaus": true,
  "josef ahorner": true,
  "dorothea kiesling": true,
  "louise kiesling": true,
  "michael ahorner": true,
  "von planta": true,
  vonplanta: true,
  "herma hutter": true,
  "ursula plasser": true,
  "markus anton": true,
  "florina anton": true,
  "louise anton": true,
  "gregor anton": true,
  "florina pantic": true,
  "louise pantic": true,
  linton: true,
  wenckheim: true,
  piech: true,
  piesh: true,
  piesch: true,
  piëch: true,
  piësh: true,
  piësch: true,
  "adolf rosenberger": true,
};
