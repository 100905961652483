// import { useEffect, useRef } from "react";
import styles from "./CutSceneVideo.module.scss";
import classNames from "classnames";
import dynamic from "next/dynamic";
import { useEffect, useRef } from "react";

const LazyReactPlayer = dynamic(
  () => import("../../atoms/video/PlayerWrapper"),
  {
    ssr: false,
  }
);
type CutSceneProps = {
  play: boolean;
  onCutSceneFinished: () => void;
  className?: string;
  half?: boolean;
  src: string;
  videoDuration: number; // in ms
};

export default function CutSceneVideo({
  onCutSceneFinished,
  play,
  src,
  className,
  half,
  videoDuration,
}: CutSceneProps) {
  const oldPlay = useRef(false);
  const timer = useRef<NodeJS.Timer>();
  useEffect(() => {
    if (!oldPlay.current && play) {
      timer.current = setTimeout(() => onCutSceneFinished(), videoDuration);
    }

    oldPlay.current = play;
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [onCutSceneFinished, play, videoDuration]);

  return (
    // <div
    //   className={classNames(styles.cutSceneVideo, className, {
    //     [styles.half]: half,
    //   })}
    // >
    //   <video
    //     src={src}
    //     ref={videoRef}
    //     muted
    //     width="100%"
    //     height="100%"
    //     controls={false}
    //     playsInline
    //   />
    // </div>
    <LazyReactPlayer
      url={src}
      playing={play}
      muted
      className={classNames(
        styles.cutSceneVideo,
        {
          [styles.half]: half,
        },
        className
      )}
      width="100%"
      height="100%"
      controls={false}
      playsinline
      // loop={false}
      // loop
    />
  );
}
