import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Table: Mesh;
  };
  materials: {
    ["Desk Wood"]: MeshStandardMaterial;
  };
};

export default function Desk(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/desk.glb"
  ) as unknown as GLTFResult;

  return (
    <group {...props} dispose={null} scale={0.35}>
      <mesh
        name="Table"
        castShadow
        receiveShadow
        geometry={nodes.Table.geometry}
        material={materials["Desk Wood"]}
        position={[0, 0, 0]}
      />
    </group>
  );
}

// useGLTF.preload("/assets/models/desk.glb");
