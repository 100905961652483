import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder020: Mesh;
    Cylinder020_1: Mesh;
    Cylinder020_2: Mesh;
    Cylinder020_3: Mesh;
    Cylinder020_4: Mesh;
    Cylinder020_5: Mesh;
    Cylinder020_6: Mesh;
    Cylinder020_7: Mesh;
  };
  materials: {
    Housing: MeshStandardMaterial;
    Light: MeshStandardMaterial;
  };
};

export default function NeonLights(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/neon_lights.glb"
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <group
        name="Neon_Light_V3001"
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.6}
      >
        <mesh
          name="Cylinder020"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020.geometry}
          material={materials.Housing}
        />
        <mesh
          name="Cylinder020_1"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_1.geometry}
          material={materials.Light}
        />
        <mesh
          name="Cylinder020_2"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_2.geometry}
          material={materials.Housing}
        />
        <mesh
          name="Cylinder020_3"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_3.geometry}
          material={materials.Light}
        />
        <mesh
          name="Cylinder020_4"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_4.geometry}
          material={materials.Housing}
        />
        <mesh
          name="Cylinder020_5"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_5.geometry}
          material={materials.Light}
        />
        <mesh
          name="Cylinder020_6"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_6.geometry}
          material={materials.Housing}
        />
        <mesh
          name="Cylinder020_7"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_7.geometry}
          material={materials.Light}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/neon_lights.glb");
