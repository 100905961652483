import { forwardRef } from "react";
import { SpotLight } from "@react-three/drei";
import { DepthTexture, SpotLight as SpotLightImpl } from "three";
import { useGarageContext } from "../../../../../context/garageContext";

const CustomSpotlight = forwardRef<
  SpotLightImpl,
  JSX.IntrinsicElements["spotLight"] & {
    depthBuffer?: DepthTexture;
    attenuation?: number;
    anglePower?: number;
    radiusTop?: number;
    radiusBottom?: number;
    opacity?: number;
    color?: string | number;
    volumetric?: boolean;
    debug?: boolean;
  }
>((props, ref) => {
  const { isOldIosVersion } = useGarageContext();

  if (isOldIosVersion) {
    return null;
  }

  return <SpotLight ref={ref} {...props} />;
});

CustomSpotlight.displayName = "CustomSpotlight";
export default CustomSpotlight;
