import { KEYWORDS } from "../constants/keywords";
import {
  PORSCHE_KEYWORDS,
  porscheCarResponse,
} from "../constants/porsche_keywords";
import type { PorscheKeywordResponse } from "../constants/porsche_keywords";

export const detectKeywords = (string: string, wordList = KEYWORDS) => {
  let flagged = "";

  const split = string
    .replace(/!|\?|<|>|,|\.|\(|\)|-|\+|"|'/g, " ")
    .replace(/\s+/g, " ")
    .toLowerCase()
    .split(" ");
  split.every((word, i) => {
    Object.keys(wordList).every((key) => {
      // @ts-ignore
      if (wordList[key][word]) {
        flagged = key;
        console.log("word flagged :", word);
        return false;
      }

      if (i < split.length - 1) {
        // @ts-ignore
        if (wordList[key][word + " " + split[i + 1]]) {
          flagged = key;
          console.log("word flagged :", word + " " + split[i + 1]);
          return false;
        }
      }

      return true;
    });

    return !flagged;
  });

  return flagged;
};

export const detectPorscheKeywords = (
  string: string
): PorscheKeywordResponse => {
  let response: PorscheKeywordResponse;

  if (
    string.match(/hoe.?veel.*\bkost.?.?\b.*porsche/gi) ||
    string.match(/hoe.?veel.*porsche\bkost.?.?\b./gi)
  ) {
    return porscheCarResponse;
  }

  if (
    string.match(
      /\b(auto|car|wagen|model|type)\b.*\b(porsche|achter.?grond|back.?ground)\b/gi
    ) ||
    string.match(
      /\b(porsche|achter.?grond|back.?ground)\b.*\b(auto|car|wagen|model|type)\b/gi
    )
  ) {
    return {
      text: "De auto op de achtergrond is een Porsche Taycan. Voor vragen over onze modellen verwijs ik je graag door naar www.porsche.nl of je dichtstbijzijnde Porsche dealer.",
      innerHtml: `De auto op de achtergrond is een Porsche Taycan. Voor vragen over onze modellen verwijs ik je graag door naar <a target="_blank" rel="noopener noreferrer" href="https://porsche.nl">www.porsche.nl</a> of je dichtstbijzijnde Porsche dealer.`,
    };
  }
  const split = string
    .replace(/!|\?|<|>|,|\.|\(|\)|-|\+|"|'/g, " ")
    .replace(/\s+/g, " ")
    .toLowerCase()
    .split(" ");

  split.every((word, i) => {
    if (PORSCHE_KEYWORDS[word]) {
      response = PORSCHE_KEYWORDS[word];
      console.log("word flagged :", word);
      return false;
    }

    if (i < split.length - 1) {
      if (PORSCHE_KEYWORDS[word + " " + split[i + 1]]) {
        response = PORSCHE_KEYWORDS[word + " " + split[i + 1]];
        console.log("word flagged :", word + " " + split[i + 1]);
        return false;
      }
    }

    return true;
  });

  // @ts-ignore
  return response;
};
