import { PerspectiveCamera as DreiPerspectiveCamera } from "@react-three/drei";
import React, { useRef, useEffect } from "react";
import AudioListener from "src/lib/AudioListener/AudioListener";
import { useFrame, useThree } from "@react-three/fiber";
import Orb from "./objects/orb/Orb";
import { useDeviceStateTracker } from "../../../hooks/useDeviceStateTracker";
import {
  Group,
  Mesh,
  PerspectiveCamera,
  SpotLight,
  HemisphereLight,
  SphereGeometry,
} from "three";
import { extend } from "@react-three/fiber";

extend({
  Group,
  Mesh,
  PerspectiveCamera,
  SpotLight,
  HemisphereLight,
  SphereGeometry,
});

export default function GarageScene({ start }: { start: boolean }) {
  const { deviceState } = useDeviceStateTracker(true);
  const audioListenerRef = useRef(new AudioListener());
  const { gl } = useThree();

  useFrame(() => {
    audioListenerRef.current.isPlaying && audioListenerRef.current.update();
  });

  useEffect(() => {
    gl.setClearColor(0xffffff, 0);
  }, [gl]);

  return (
    <>
      <DreiPerspectiveCamera
        makeDefault
        fov={25}
        position={[0, 0, 7]}
        near={0.1}
        far={25}
      />
      <Orb
        start={start}
        position={[0, deviceState > 1 ? 1.0 : 1.4, 0]}
        scale={deviceState > 1 ? 1 : 0.7}
      />
      <hemisphereLight intensity={1} groundColor="black" color="red" />
      <spotLight
        position={[0, 10, -14]}
        angle={0.52}
        penumbra={1}
        intensity={1}
        castShadow
        shadow-mapSize={1024}
        color="red"
      />
    </>
  );
}
