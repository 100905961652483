import React from "react";
import styles from "./IconButtonSpinner.module.scss";

const IconButtonSpinner = () => {
  return (
    <div className={styles["lds-ring"]}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default IconButtonSpinner;
