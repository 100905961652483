import classNames from "classnames";
import styles from "./IconButton.module.scss";
import { ButtonHTMLAttributes, ReactNode, useCallback, useRef } from "react";

type ButtonProps = {
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  visible?: boolean;
  transparent?: boolean;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function IconButton({
  children,
  onClick,
  className,
  visible = true,
  transparent,
  disabled,
  ...props
}: ButtonProps) {
  const ref = useRef(null);

  const handleOnClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <button
      ref={ref}
      onClick={handleOnClick}
      className={classNames(
        styles.iconButton,
        { [styles.transparent]: transparent, [styles.hidden]: !visible },
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
}
