/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 Box_M.glb --transform -k -s -t
*/

import { useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { Fog } from "three";

export default function DynamicFog({
  color,
  fogStart,
  fogEnd,
}: {
  color: string;
  fogStart: number;
  fogEnd: number;
}) {
  const scroll = useScroll();
  const fogFarRef = useRef(15);
  const fogRef = useRef<Fog>(null);

  useFrame(() => {
    if (fogFarRef.current && fogRef.current && scroll) {
      fogRef.current.far = 15 + 5 * scroll.offset;
    }
  });

  return <fog ref={fogRef} attach="fog" args={[color, fogStart, fogEnd]} />;
}
