import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Box_L_Light: Mesh;
  };
  materials: {
    ["Lower Cardboard"]: MeshStandardMaterial;
  };
};

export function BoxLLight(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Box_L_light.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.35}>
      <mesh
        name="Box_L_Light"
        castShadow
        receiveShadow
        geometry={nodes.Box_L_Light.geometry}
        material={materials["Lower Cardboard"]}
        // position={[0, 0, 3.26]}
        // rotation={[0, Math.PI / 2, 0]}
        scale={1.03}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/Box_L_light.glb");
