/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 Box_M.glb --transform -k -s -t
*/

import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Box_M: Mesh;
  };
  materials: {
    ["Cardboard Box Final"]: MeshStandardMaterial;
  };
};

export function BoxM(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Box_M.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.35}>
      <mesh
        name="Box_M"
        castShadow
        receiveShadow
        geometry={nodes.Box_M.geometry}
        material={materials["Cardboard Box Final"]}
        // position={[-3.57, 0, 0]}
        scale={[0.65, 0.64, 0.64]}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/Box_M.glb");
