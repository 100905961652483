import Scribble from "./Scribble";
import { useGarageContext } from "../../../../../context/garageContext";

export const scribbles: Array<{
  src: string;
  position: [x: number, y: number, z: number];
  rotationZ?: number;
  ratioHeight: number;
}> = [
  {
    src: "/assets/images/scribbles/What_if.png",
    position: [2.3, 1.9, 2.5],
    rotationZ: Math.PI * 0.1,
    ratioHeight: 0.41,
  },
  {
    src: "/assets/images/scribbles/Proberen_Testen_Opstaan.png",
    position: [-2.5, 1.25, 0],
    ratioHeight: 0.32,
  },
  {
    src: "/assets/images/scribbles/Circle.png",
    position: [3, 3.75, -3],
    ratioHeight: 0.84,
  },
  {
    src: "/assets/images/scribbles/Concept.png",
    position: [1.5, 0.25, -6.75],
    ratioHeight: 0.46,
  },
  {
    src: "/assets/images/scribbles/DDD.png",
    position: [-3.5, 3.75, -8.5],
    rotationZ: Math.PI * 0.1,
    ratioHeight: 0.82,
  },
  {
    src: "/assets/images/scribbles/Degrees.png",
    position: [5.4, 1, -14.5],
    ratioHeight: 1.1,
  },
  {
    src: "/assets/images/scribbles/Math.png",
    position: [2, 5, -15.5],
    rotationZ: Math.PI * -0.1,
    ratioHeight: 0.44,
  },
  {
    src: "/assets/images/scribbles/XXX.png",
    position: [-2.75, 4.25, -16.5],
    rotationZ: Math.PI * 0.1,
    ratioHeight: 0.42,
  },
];

export default function Scribbles(): JSX.Element | null {
  const { smallScreen } = useGarageContext();
  if (smallScreen) return null;
  return (
    <>
      {scribbles.map((scribble, i) => (
        <Scribble
          key={scribble.position.join("-")}
          src={scribble.src}
          position={scribble.position}
          rotation-z={scribble.rotationZ || 0}
          ratioHeight={scribble.ratioHeight}
          receiveShadow={false}
          castShadow={false}
        />
      ))}
    </>
  );
}
