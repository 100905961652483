import { Plane, useTexture } from "@react-three/drei";
import { RepeatWrapping } from "three";

export default function Ceiling(props: JSX.IntrinsicElements["mesh"]) {
  const textures = useTexture(
    {
      map: "/assets/textures/plaster/beige_wall_diff.jpg",
      // displacementMap: "/assets/textures/plaster/beige_wall_disp.jpg",
      normalMap: "/assets/textures/plaster/beige_wall_normal.jpg",
      roughnessMap: "/assets/textures/plaster/beige_wall_rough.jpg",
      aoMap: "/assets/textures/plaster/beige_wall_ao.jpg",
    },
    (textures) => {
      if (Array.isArray(textures))
        textures.forEach((texture) => {
          // texture.repeat.set(5, 5);
          texture.wrapT = RepeatWrapping;
          texture.wrapS = RepeatWrapping;
        });
    }
  );
  return (
    <Plane
      {...props}
      args={[55, 15]}
      // receiveShadow
      rotation-z={Math.PI * 0.5}
      rotation-x={Math.PI * 0.5}
      // rotation-y={-Math.PI / 2}
      position={[0, 6.5, -7]}
    >
      <meshStandardMaterial {...textures} />
    </Plane>
  );
}

useTexture.preload("/assets/textures/plaster/beige_wall_diff.jpg");
useTexture.preload("/assets/textures/plaster/beige_wall_normal.jpg");
useTexture.preload("/assets/textures/plaster/beige_wall_rough.jpg");
useTexture.preload("/assets/textures/plaster/beige_wall_ao.jpg");
