import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    A4: Mesh;
  };
  materials: {
    ["A4 Poster 1"]: MeshStandardMaterial;
  };
};

export default function A4Poster1(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/A4_Poster_1.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.35}>
      <mesh
        name="A4"
        castShadow
        receiveShadow
        geometry={nodes.A4.geometry}
        material={materials["A4 Poster 1"]}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/A4_Poster_1.glb");
