export type PorscheKeywordResponse = {
  text: string;
  innerHtml: string;
};

export const porscheCarResponse = {
  text: "Je kunt hier terecht voor vragen over ondernemerschap. Voor vragen over onze modellen verwijs ik je graag door naar www.porsche.nl of je dichtstbijzijnde Porsche dealer.",
  innerHtml: `Je kunt hier terecht voor vragen over ondernemerschap. Voor vragen over onze modellen verwijs ik je graag door naar <a target="_blank" rel="noopener noreferrer" href="https://porsche.nl">www.porsche.nl</a> of je dichtstbijzijnde Porsche dealer.`,
};

export const porscheDealersResponse = {
  text: "Alle Porsche Centra zijn gelijkwaardig. Elke vestiging heeft deskundig personeel en de beste selectie auto's en onderdelen. Bovendien bieden ze allemaal een uitstekende klantenservice en een geweldige garantie.",
  innerHtml: `Alle Porsche Centra zijn gelijkwaardig. Elke vestiging heeft deskundig personeel en de beste selectie auto's en onderdelen. Bovendien bieden ze allemaal een uitstekende klantenservice en een geweldige garantie.`,
};

export const PORSCHE_KEYWORDS: Record<string, PorscheKeywordResponse> = {
  porsche24: {
    text: "Porsche24 is het loyaliteitsprogramma van Porsche. Porsche24 beloont Porsche eigenaren voor rijden en deelname aan evenementen. Download de app voor meer informatie:",
    innerHtml: `Porsche24 is het loyaliteitsprogramma van Porsche. Porsche24 beloont Porsche eigenaren voor rijden en deelname aan evenementen. Download de app voor meer informatie: <a target="_blank" rel="noopener noreferrer" href="https://www.porsche.com/netherlands/nl/motorsportandevents/porsche24/#downloadapp">https://www.porsche.com/netherlands/nl/motorsportandevents/porsche24/#downloadapp</a>`,
  },
  "porsche 24": {
    text: "Porsche24 is een initiatief dat Porsche rijders beloont voor rijden en deelname aan events. Download de app hier!",
    innerHtml: `Porsche24 is een initiatief dat Porsche rijders beloont voor rijden en deelname aan events. Download de app hier: <a target="_blank" rel="noopener noreferrer" href="https://www.porsche.com/netherlands/nl/motorsportandevents/porsche24/#downloadapp">https://www.porsche.com/netherlands/nl/motorsportandevents/porsche24/#downloadapp</a>`,
  },
  "test drive": {
    text: "Je kunt hier terecht voor vragen over ondernemerschap. Voor het boeken van een proefrit verwijs ik je graag door naar een van de Porsche Centra.",
    innerHtml: `Je kunt hier terecht voor vragen over ondernemerschap. Voor het boeken van een proefrit verwijs ik je graag door naar een van de Porsche Centra.`,
  },
  testdrive: {
    text: "Je kunt hier terecht voor vragen over ondernemerschap. Voor het boeken van een proefrit verwijs ik je graag door naar een van de Porsche Centra.",
    innerHtml: `Je kunt hier terecht voor vragen over ondernemerschap. Voor het boeken van een proefrit verwijs ik je graag door naar een van de Porsche Centra.`,
  },
  "proefrit verwijs": {
    text: "Je kunt hier terecht voor vragen over ondernemerschap. Voor het boeken van een proefrit verwijs ik je graag door naar een van de Porsche Centra.",
    innerHtml: `Je kunt hier terecht voor vragen over ondernemerschap. Voor het boeken van een proefrit verwijs ik je graag door naar een van de Porsche Centra.`,
  },
  "auto kopen": {
    text: "Voor het aanschaffen van een Porsche verwijs ik je graag naar www.porsche.nl of een van de Porsche Centra.",
    innerHtml: `Voor het aanschaffen van een Porsche verwijs ik je graag naar <a target="_blank" rel="noopener noreferrer" href="https://porsche.nl">www.porsche.nl</a> of een van de Porsche Centra.`,
  },
  "auto configurator": {
    text: "Voor het samenstellen van een Porsche verwijs ik je graag door naar www.porsche.nl.",
    innerHtml: `Voor het samenstellen van een Porsche verwijs ik je graag door naar <a target="_blank" rel="noopener noreferrer" href="https://porsche.nl">www.porsche.nl</a>`,
  },
  "car configurator": {
    text: "Voor het samenstellen van een Porsche verwijs ik je graag door naar www.porsche.nl.",
    innerHtml: `Voor het samenstellen van een Porsche verwijs ik je graag door naar <a target="_blank" rel="noopener noreferrer" href="https://porsche.nl">www.porsche.nl</a>`,
  },
  "porsche social": {
    text: "Je kunt hier terecht voor vragen over ondernemerschap. Wil je Porsche volgen op social? Vind ons op Linkedin en Instagram.",
    innerHtml: `Je kunt hier terecht voor vragen over ondernemerschap. Wil je Porsche volgen op social? Vind ons op <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/showcase/porsche-nederland/">LinkedIn</a> en <a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/porsche_nederland/">Instagram</a>`,
  },
  "porsche service": {
    text: "Voor vragen over service van Porsche verwijs ik je graag door naar www.porsche.nl of een van Porsche Centra.",
    innerHtml: `Voor vragen over service van Porsche verwijs ik je graag door naar <a target="_blank" rel="noopener noreferrer" href="https://www.porsche.nl">www.porsche.nl</a> of een van Porsche Centra.`,
  },
  "porsche dealers": porscheDealersResponse,
  "car dealers": porscheDealersResponse,
  "porsche dealer": porscheDealersResponse,
  "car dealer": porscheDealersResponse,
  "356": porscheCarResponse,
  "550": porscheCarResponse,
  "718": porscheCarResponse,
  "904": porscheCarResponse,
  "911": porscheCarResponse,
  "906": porscheCarResponse,
  "912": porscheCarResponse,
  "914": porscheCarResponse,
  "918": porscheCarResponse,
  "930": porscheCarResponse,
  "924": porscheCarResponse,
  "928": porscheCarResponse,
  "944": porscheCarResponse,
  "959": porscheCarResponse,
  "968": porscheCarResponse,
  "982": porscheCarResponse,
  porsche356: porscheCarResponse,
  porsche550: porscheCarResponse,
  porsche718: porscheCarResponse,
  porsche904: porscheCarResponse,
  porsche911: porscheCarResponse,
  porsche906: porscheCarResponse,
  porsche912: porscheCarResponse,
  porsche914: porscheCarResponse,
  porsche918: porscheCarResponse,
  porsche930: porscheCarResponse,
  porsche924: porscheCarResponse,
  porsche928: porscheCarResponse,
  porsche944: porscheCarResponse,
  porsche959: porscheCarResponse,
  porsche968: porscheCarResponse,
  porsche982: porscheCarResponse,
  boxster: porscheCarResponse,
  cayenne: porscheCarResponse,
  carrera: porscheCarResponse,
  panamera: porscheCarResponse,
  spyder: porscheCarResponse,
  macan: porscheCarResponse,
  cayman: porscheCarResponse,
  taycan: porscheCarResponse,
};
