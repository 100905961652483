import React, { useEffect, useRef } from "react";
import Button from "../../atoms/button/Button";
import gsap from "gsap";
import styles from "./GarageSceneQuestions.module.scss";

interface DiscoverNewsletterButtonProps {
  visible: boolean;
  className?: string;
}

const DiscoverNewsletterButton = ({
  visible,
  className,
}: DiscoverNewsletterButtonProps) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (visible) {
      gsap.to(buttonRef.current, {
        height: 40,
      });
    } else {
      gsap.to(buttonRef.current, {
        height: 0,
      });
    }
  }, [visible]);

  return (
    <div className={styles.newsletterButtonContainer} ref={buttonRef}>
      <Button
        label="Meld je nu aan"
        buttonIcon="arrow-right"
        href="https://campaign.porsche.nl/subscribe?utm_source=garagementality_campagnewebsite&utm_campaign=garagementality&utm_content=ai"
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      />
    </div>
  );
};

export default DiscoverNewsletterButton;
