export const GODLY_KEYWORDS = [
  "merel van helsdingen",
  "helsdingen",
  "natasha greenhalgh",
  "greenhalgh",
  "nxt",
  "nxt museum",
  "zeynep dag",
  "zeynep",
  "guillaume philibert",
  "guilaume philibert",
  "guillaume philibert",
  "phillibert",
  "philliberd",
  "philliber",
  "philibert",
  "philibert chin",
  "unidentified fluid other",
  "filling pieces",
  "ready to wear",
  "ready-to-wear",
  "alzuar",
  "alzuarr",
  "alzúarr",
  "schoenenontwerpster",
  "dutch entrepeneurs",
  "nederlandse ondernemers",
  "nederlandse ceos",
  "nederlandse garage mentaliteit",
  "krachtige vrouwen",
  "nederlandse garage",
  "ron simpson",
  "skybox",
  "avocado show",
  "scholten",
  "messing",
  "for you agency",
  "tom peeters",
  "tom peters",
  "crisp",
  "julien zaal",
  "shawn harris",
  "zalando",
  "michiel roodenburg",
  "eric klaassen",
  "josette",
  "josette de vroeg",
  "NoWa",
  "nowa",
  "1off",
  "1/off",
  "1 off",
  "van wijngaarden",
  "wijngaarden",
  "wijngarden",
];
