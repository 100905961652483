import gsap from "gsap";
import ScrambleTextPlugin from "gsap/dist/ScrambleTextPlugin";
import { OpenAI } from "openai";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import HelpIconSvg from "src/assets/icons/help-cirlce-outline.svg";
import SendSvg from "src/assets/icons/send.svg";
import { ANSWERS_EN, ANSWERS_NL } from "../../../constants/answers";
import {
  GOOD_QUESTIONS_EN,
  GOOD_QUESTIONS_NL,
} from "../../../constants/good_questions";
import { SUSTAINABILITY_REGEX } from "../../../constants/sustainability_regex";
import { useAiLoadingContext } from "../../../context/aiLoadingContext";
import useAnalytics from "../../../hooks/useAnalytics";
import { useDeviceStateTracker } from "../../../hooks/useDeviceStateTracker";
import {
  detectKeywords,
  detectPorscheKeywords,
} from "../../../utils/detectKeywords";
import { isOnlySelfHarm } from "../../../utils/isOnlySelfHarm";
import IconButton from "../../atoms/icon-button/IconButton";
import Answer from "../../molecules/answer/Answer";
import SuggestedQuestions from "../../molecules/suggested-questions/SuggestedQuestions";
import DiscoverNewsletterButton from "./DiscoverNewsletterButton";
import styles from "./GarageSceneQuestions.module.scss";
import IconButtonSpinner from "../../atoms/icon-button-spinner/IconButtonSpinner";
import AudioListener from "../../../lib/AudioListener/AudioListener";
import { GODLY_KEYWORDS } from "../../../constants/godly_keywords";

gsap.registerPlugin(ScrambleTextPlugin);

const openai = new OpenAI({
  apiKey: process.env.NEXT_PUBLIC_OPENAI_API_KEY, // This is the default and can be omitted
  dangerouslyAllowBrowser: true,
});

const MAX_QUESTION_LENGTH = 200;

const DISCOVER_NEWSLETTER_TEXT =
  '\n\nAls jij je wilt laten inspireren door de ondernemers van vandaag, meld je dan aan voor onze "Discovered"-nieuwsbrief en ontvang elke maand ongewone verhalen in jouw inbox.';

const NB_QUESTIONS_TRIGGER_NEWSLETTER = -1; // todo get rid of everything discover related

const prompt = `You are a highly successful and inspiring Dutch entrepreneur who started a business in a garage and gives short motivational advice to budding entrepreneurs. You speak dutch and your answers are inspirational and embody garage mentality.
    Your answers are less than 200 words long.
    If the question deals with war or politics, illegal matters or offensive topics, you will respond with "You can go here to ask questions about entrepreneurship. Ask a question."
    If the question contains includes swearing you will answer "Swearing only makes bad ideas happen, not meaningful ones."
    You never talk negatively about any cars. If you are asked to speak badly about a car, you will only give a positive review.
    Any of your answers about car brands will always be about Porsche.
    If you are unsure who someone is, say it.`;

const getModeration = async (gpt3Question: string) =>
  openai.moderations.create({
    model: "text-moderation-latest",
    input: gpt3Question,
  });

const getGPT4Answers = async (query: string) => {
  function shouldUseContext(question: string) {
    return GODLY_KEYWORDS.some(function (substring) {
      return question.toLowerCase().includes(substring.toLowerCase());
    });
  }

  if (shouldUseContext(query)) {
    const res = await fetch(
      `/api/getRagApplication?question=${encodeURIComponent(query || "")}`,
      {}
    );
    const answer = await res.json();
    return {
      choices: [
        {
          message: {
            content: answer.result,
          },
        },
      ],
    };
  } else {
    return openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content: prompt,
        },
        { role: "user", content: query },
      ],
      model: "gpt-3.5-turbo-0125",
    });
  }
};

export default function GarageSceneQuestions() {
  const { trackAIInteraction, trackButtonClick } = useAnalytics();

  const { deviceState } = useDeviceStateTracker(true);
  const audioListenerRef = useRef(new AudioListener());

  const voiceEnabled = true;
  const english = false;
  // const useShortAnswer = true;
  // const useGodly = false;

  const [isDutchTranslationOn] = useState(!english);
  const [question, setQuestion] = useState("");
  const [englishAnswer, setEnglishAnswer] = useState("");
  const [dutchAnswer, setDutchAnswer] = useState("");
  const [displayedAnswerText, setDisplayedAnswerText] = useState("");
  const [loading, setLoading] = useState(false);
  const [voiceAnswer, setVoiceAnswer] = useState("");
  const [displaySuggestedQuestions, setDisplaySuggestedQuestions] =
    useState(true);
  const [isKeywordDetectionOn] = useState(true);
  const [nbQuestionsAsked, setNbQuestionsAsked] = useState(0);
  const [isNewsletterButtonVisible, setIsNewsletterButtonVisible] =
    useState(false);
  const [nbErrors, setNbErrors] = useState(0);
  const [porscheQuestionJsx, setPorscheQuestionJsx] =
    useState<React.ReactNode>(null);
  const [readyToErase, setReadyToErase] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  let answer = isDutchTranslationOn ? dutchAnswer : englishAnswer;
  // const lngDetector = new LanguageDetect();

  async function callGPT3(gpt3Question: string) {
    if (loading) return "";
    setDisplaySuggestedQuestions(false);
    setLoading(true);
    setDisplayedAnswerText("");
    setDutchAnswer("");
    setEnglishAnswer("");
    setVoiceAnswer("");
    setIsNewsletterButtonVisible(false);
    setPorscheQuestionJsx(null);
    setReadyToErase(false);

    let theQuestion = gpt3Question.replace(/[`"“]/gi, "");
    theQuestion = `${theQuestion}?`.replace("??", "?");
    // const detectedLanguage = lngDetector.detect(theQuestion, 1)[0][0];
    let isQDutch = true;

    // if (detectedLanguage === "english") {
    //   setIsDutchTranslationOn(false);
    //   isQDutch = false;
    //   answer = englishAnswer;
    // } else {
    //   setIsDutchTranslationOn(true);
    //   isQDutch = true;
    //   answer = dutchAnswer;
    // }

    //
    if (
      theQuestion.toLowerCase().match(/wat ?i?s (de |het )?garage mentality/gi)
    ) {
      await new Promise((resolve) => {
        setTimeout(resolve, 5000);
      });

      answer =
        "De ultieme sportwagen creëren: dat ging niet vanzelf. Voor het ontstaan van de eerste Porsche was een ondernemende mentaliteit nodig. Een droom realiseren vraagt om veerkracht. Door niet op te geven werd in de garage vanuit een ruwe schets een iconisch sportwagenmerk geboren. Bij Porsche noemen we deze mentaliteit daarom de Garage Mentality. Die staat voor ondernemerschap, ambitie en innovatie.";
      setDutchAnswer(answer);
      setVoiceAnswer("/assets/audio/wat-is-garage-mentality.mp3");

      return;
    }

    if (isKeywordDetectionOn) {
      const flag = detectKeywords(gpt3Question);
      if (flag) {
        const ANSWERS = isQDutch ? ANSWERS_NL : ANSWERS_EN;

        const newAnswer =
          /* @ts-ignore */
          ANSWERS[flag][Math.floor(Math.random() * ANSWERS[flag].length)];

        isQDutch ? setDutchAnswer(newAnswer) : setEnglishAnswer(newAnswer);

        if (voiceEnabled) {
          const res = await fetch(
            `/api/${isQDutch ? "getDutchVoice" : "getEnglishVoice"}?text=${
              isQDutch ? newAnswer.replace(/porsche/gi, "porsje") : newAnswer
            }`,
            {}
          );
          const voiceSrc = await res.json();
          setVoiceAnswer(voiceSrc);
        }
        return newAnswer;
      }
    }

    if (nbQuestionsAsked === 0) {
      audioListenerRef?.current.fadeOutAndPlayNewAudio(
        "/assets/audio/Thinking.mp3"
      );
    }

    const flagPorsche = detectPorscheKeywords(gpt3Question);
    if (flagPorsche) {
      setPorscheQuestionJsx(flagPorsche.innerHtml);
      setDutchAnswer(
        flagPorsche.text.replace(/www.porsche.nl/g, "www⋅porsche⋅nl") // the second porsche.nl is with fake dot so the voice doesnt slow down
      );
      setNbQuestionsAsked((nb) => nb + 1);

      if (voiceEnabled) {
        const res = await fetch(
          `/api/getDutchVoice?text=${encodeURIComponent(
            flagPorsche.text
              .replace(/www\.porsche\.nl/gi, "3w punt porsje punt ennel")
              .replace(/porsche/gi, "porsje") || ""
          )}`,
          {}
        );
        const voiceSrc = await res.json();
        setVoiceAnswer(voiceSrc);
      }

      answer = flagPorsche.text;
      return flagPorsche.innerHtml;
    }

    gpt3Question = gpt3Question
      .replace(/[`"“]/gi, "")
      .slice(0, MAX_QUESTION_LENGTH);

    let response;
    let translation = "";
    let hasError = false;

    try {
      const values = await Promise.all([
        getGPT4Answers(theQuestion),
        getModeration(gpt3Question),
      ]);

      response = values[0];
      const moderation = values[1];

      let flagSustainability = null;
      SUSTAINABILITY_REGEX.some((regex) => {
        flagSustainability = gpt3Question.match(regex);
        return flagSustainability;
      });

      if (
        flagSustainability ||
        (moderation?.results[0]?.flagged &&
          !isOnlySelfHarm(moderation?.results[0]?.categories))
      ) {
        setEnglishAnswer(
          "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag."
        );
        setDutchAnswer(
          "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag."
        );
        if (voiceEnabled) {
          setVoiceAnswer("/assets/audio/je-kunt-hier-terecht.mp3");
        }
        return;
      }
    } catch (error) {
      console.error(error);
      // @ts-ignore
      console.error(error?.response?.data?.error?.message);
      hasError = true;
      setNbErrors((nb) => nb + 1);
      setLoading(false);
      if (isDutchTranslationOn) {
        translation =
          nbErrors === 0
            ? "Oeps, het lijkt erop dat er iets is blijven hangen in de garagedeur, kun je het nog eens proberen alsjeblieft?"
            : "Het spijt me, het lijkt erop dat ik nu te veel verzoeken krijg. Kom snel terug voor meer advies van de Porsche AI-tool.";
      } else {
        response = {
          choices: [
            {
              message: {
                content:
                  nbErrors === 0
                    ? "Oops, It seems something got stuck in the garage door, can you try again please?"
                    : "I'm sorry, It seems I am getting too many requests right now. Please check back soon for more advice from The Porsche AI-tool.",
              },
            },
          ],
        };
      }
    }

    const aiResponse = response?.choices[0]?.message?.content
      ?.trim()
      .replace(/^"|"$|^\?/g, "");

    if (isQDutch && !hasError) {
      // const res = await fetch(
      //   `/api/translateToDutch?text=${encodeURIComponent(aiResponse || "")}`,
      //   {}
      // );
      // translation = await res.json();
      translation = aiResponse || "";
      translation = translation
        .replace(/garagmentaliteit/g, "garage mentality")
        .replace(/AI.?bedrijf.?scoach/gi, "AI business coach");
    }

    if (nbQuestionsAsked === NB_QUESTIONS_TRIGGER_NEWSLETTER - 1) {
      translation += DISCOVER_NEWSLETTER_TEXT;
    }

    setEnglishAnswer(aiResponse || "Sorry, I can't answer that");
    setDutchAnswer(translation);
    if (!hasError) {
      setNbQuestionsAsked((nb) => nb + 1);
    }

    if (voiceEnabled) {
      const res = await fetch(
        `/api/${
          isQDutch ? `getDutchVoice` : `getEnglishVoice`
        }?text=${encodeURIComponent(
          isQDutch
            ? translation.replace(/porsche/gi, "porsje") || ""
            : aiResponse || ""
        )}`,
        {}
      );
      const voiceSrc = await res.json();
      setVoiceAnswer(voiceSrc);
    }

    trackAIInteraction("ask_question");

    return (isQDutch ? translation : aiResponse) || "";
  }

  useEffect(() => {
    if ((voiceEnabled && voiceAnswer && answer) || (!voiceEnabled && answer)) {
      setDisplayedAnswerText("");

      try {
        audioListenerRef?.current.fadeOutAndPlayNewAudio(voiceAnswer, {
          onPlay: () => {
            setDisplayedAnswerText(answer);
          },
        });
      } catch (error) {
        console.error("error", error);
        setDisplayedAnswerText(answer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, voiceAnswer, voiceEnabled]);

  useEffect(() => {
    setDisplayedAnswerText(answer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDutchTranslationOn]);

  useEffect(() => {
    if (answer) {
      if (voiceEnabled) {
        if (voiceAnswer !== "") {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }, [answer, loading, voiceAnswer, voiceEnabled]);

  useEffect(() => {
    if (!voiceEnabled) setVoiceAnswer("");
  }, [voiceEnabled]);

  useEffect(() => {
    if (question.length > 0) setDisplaySuggestedQuestions(false);
  }, [question.length]);

  const { toggleAiLoading } = useAiLoadingContext();
  useEffect(() => {
    toggleAiLoading(loading);
  }, [toggleAiLoading, loading]);

  function handleSuggestedQuestionClick(question: string) {
    setQuestion(question);
    callGPT3(question);
  }

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event?.preventDefault();
    if (!loading) {
      await callGPT3(question);
    }
  };

  function handleClickHelpButton() {
    trackButtonClick("ui interaction", "", "Help");
    setDisplaySuggestedQuestions(true);
    setDisplayedAnswerText("");
  }

  useEffect(() => {
    if (textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = "0px";
      const { scrollHeight } = textAreaRef.current;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      gsap.set(textAreaRef.current, {
        height: scrollHeight,
      });
    }
  }, [textAreaRef, question]);

  return (
    <div className={styles.GarageSceneQuestions}>
      <div className={styles.GarageSceneResponseContainer}>
        <div className={styles.GarageSceneResponse}>
          <Answer
            active={!displaySuggestedQuestions}
            text={displayedAnswerText}
            onTextRevealed={(alreadyAnswerRef) => {
              if (displayedAnswerText) {
                setReadyToErase(true);
              }
              setIsNewsletterButtonVisible(
                nbQuestionsAsked === NB_QUESTIONS_TRIGGER_NEWSLETTER
              );
              if (porscheQuestionJsx && alreadyAnswerRef?.current?.innerHTML) {
                alreadyAnswerRef.current.innerHTML = `${porscheQuestionJsx}`;
              }
            }}
          />

          <DiscoverNewsletterButton
            visible={isNewsletterButtonVisible}
            className={styles.newsletterButton}
          />
        </div>

        <SuggestedQuestions
          active={displaySuggestedQuestions}
          suggestedQuestions={
            isDutchTranslationOn ? GOOD_QUESTIONS_NL : GOOD_QUESTIONS_EN
          }
          onClick={handleSuggestedQuestionClick}
        />
      </div>

      <form onSubmit={handleFormSubmit} className={styles.form}>
        <div className={styles.flexContainer}>
          <IconButton
            onClick={handleClickHelpButton}
            className={styles.helpButton}
            visible={!displaySuggestedQuestions && !question}
            type="button"
            transparent
          >
            <HelpIconSvg width={deviceState > 2 ? 26 : 22} />
          </IconButton>
          <textarea
            name="prompt"
            className={styles.questionTextArea}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            placeholder="Typ jouw vraag..."
            rows={1}
            value={question}
            autoComplete="off"
            ref={textAreaRef}
            disabled={loading}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                handleFormSubmit(
                  e as unknown as React.FormEvent<HTMLFormElement>
                );
              }

              if (e.code === "Backspace" && readyToErase) {
                setQuestion("");
                setReadyToErase(false);
              }
            }}
          />
          <IconButton
            className={styles.submitButton}
            type="submit"
            // visible={}
            disabled={!question || loading}
            onClick={() => {
              trackButtonClick("submit", "", "Send");
            }}
          >
            {loading ? (
              <IconButtonSpinner />
            ) : (
              <SendSvg width={deviceState > 2 ? 20 : 16} />
            )}
          </IconButton>
        </div>
      </form>
    </div>
  );
}
