import { MeshReflectorMaterial, Plane, useTexture } from "@react-three/drei";
import { RepeatWrapping } from "three";

export default function Floor() {
  const textures = useTexture(
    {
      // map: "/assets/textures/plaster/beige_floor_diff.jpg",
      // displacementMap: "/assets/textures/plaster/beige_wall_disp.jpg",
      normalMap: "/assets/textures/surface_imperfections_normal.jpg",
      // roughnessMap: "/assets/textures/surface_imperfections_rough.jpg",
      // aoMap: "/assets/textures/plaster/beige_wall_ao.jpg",
    },
    (textures) => {
      if (Array.isArray(textures))
        textures.forEach((texture) => {
          texture.repeat.set(6, 6);
          // texture.encoding = LinearEncoding;
          texture.wrapT = RepeatWrapping;
          texture.wrapS = RepeatWrapping;
        });
    }
  );

  return (
    <>
      <Plane
        args={[15, 55]}
        rotation-x={-Math.PI / 2}
        position={[0, 0, -7]}
        receiveShadow
      >
        <MeshReflectorMaterial
          blur={[300, 100]}
          resolution={1024}
          mixBlur={1}
          mixStrength={0.6}
          roughness={0.3}
          depthScale={0.3}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.3}
          mirror={0}
          {...textures}
          color="grey"
          // roughness={0.35}
          // metalness={0}
          // color="white"
          // debug={1}
          // displacementScale={1}
        />
      </Plane>
      {/*<Plane*/}
      {/*  args={[15, 50]}*/}
      {/*  rotation-x={-Math.PI / 2}*/}
      {/*  position={[0, 0, -7]}*/}
      {/*  receiveShadow*/}
      {/*  castShadow*/}
      {/*>*/}
      {/*  <meshStandardMaterial {...textures} roughness={0.1} />*/}
      {/*</Plane>*/}
    </>
  );
}

useTexture.preload("/assets/textures/surface_imperfections_normal.jpg");
