/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 Poster_Holder_M.glb --transform -k -s -t
*/

import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder006: Mesh;
    Cylinder006_1: Mesh;
  };
  materials: {
    Outer: MeshStandardMaterial;
    Caps: MeshStandardMaterial;
  };
};

export default function PosterHolderM(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Poster_Holder_M.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.45}>
      <group name="Poster_Holder_M" scale={[1, 0.64, 1]}>
        <mesh
          name="Cylinder006"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006.geometry}
          material={materials.Outer}
        />
        <mesh
          name="Cylinder006_1"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006_1.geometry}
          material={materials.Caps}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/Poster_Holder_M.glb");
