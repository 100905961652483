import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube056: Mesh;
    Cube056_1: Mesh;
    Cube056_2: Mesh;
  };
  materials: {
    Passpartout: MeshStandardMaterial;
    ["A3 Poster 3"]: MeshStandardMaterial;
    ["Wood Frame"]: MeshStandardMaterial;
  };
};

export default function FramedPoster(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Framed_Image.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <group name="Framed_Image" position={[0, 0, 0]} scale={0.8}>
        <mesh
          name="Cube056"
          castShadow
          receiveShadow
          geometry={nodes.Cube056.geometry}
          material={materials.Passpartout}
        />
        <mesh
          name="Cube056_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube056_1.geometry}
          material={materials["A3 Poster 3"]}
        />
        <mesh
          name="Cube056_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube056_2.geometry}
          material={materials["Wood Frame"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/Framed_Image.glb");
