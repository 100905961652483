import gsap from "gsap";
import { useEffect, useRef } from "react";
import styles from "./ScrollIndicator.module.scss";

const ScrollIndicator = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.fromTo(
      containerRef.current,
      {
        opacity: 0,
        yPercent: 50,
      },
      {
        opacity: 0.9,
        duration: 0.75,
        yPercent: 0,
        ease: "easeOut",
      }
    );
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <p>Scroll en ontdek</p>
      <span className={styles.indicator} />
    </div>
  );
};

export default ScrollIndicator;
