import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    A4001: Mesh;
  };
  materials: {
    ["A4 Poster 2"]: MeshStandardMaterial;
  };
};

export default function A4Poster2(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/A4_Poster_2.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.35}>
      <mesh
        name="A4001"
        castShadow
        receiveShadow
        geometry={nodes.A4001.geometry}
        material={materials["A4 Poster 2"]}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/A4_Poster_2.glb");
