export const SENSITIVE_FIGURES = {
  baudet: true,
  beatrix: true,
  berlusconi: true, // businessman
  "boris johnson": true,
  biden: true,
  erdogan: true,
  erdoğan: true,
  fascist: true,
  fascism: true,
  fascists: true,
  goebbels: true,
  himmler: true,
  hitler: true,
  jinping: true,
  kadyrov: true,
  "le pen": true,
  lenin: true,
  macron: true,
  mao: true,
  merkel: true,
  mussolini: true,
  nazi: true,
  nazis: true,
  obama: true,
  poetin: true,
  putin: true,
  stalin: true,
  trudeau: true,
  trump: true, // businessman
  truss: true,
  wilders: true,
  "willem alexander": true,
  "willem-alexander": true,
  zedong: true,
  zelenski: true,
  zelensky: true,
  zelenskyy: true,
};
