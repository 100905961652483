import { useProgress } from "@react-three/drei";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import styles from "./SpinningLoader.module.scss";

const ARRAY_00_100: Array<string> = [];
for (let i = 0; i <= 100; i++) {
  ARRAY_00_100.push(`${i}`.padStart(3, "0"));
}

interface SpinningLoaderProps {
  onComplete?: () => void;
  showLoaderOverlay: boolean;
}

// https://greensock.com/forums/topic/23530-some-issues-with-gsap-3-and-3d-transforms-on-the-z-axis/
// todo: make it a bit more snappy / steppy

const progressTimeline = gsap.timeline();

const SpinningLoader = ({
  onComplete,
  showLoaderOverlay,
}: SpinningLoaderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wheelRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<gsap.core.Tween | null>(null);
  const counter = useRef(0);
  const previousProgress = useRef(0);

  const { progress: dreiProgress } = useProgress();

  const goToProgress = (
    animationRef: React.RefObject<gsap.core.Tween>,
    progress: number
  ) => {
    if (progress === 1) {
      progressTimeline.kill();
      gsap.to(animationRef.current, {
        progress,
        duration: 0.2,
        ease: "Power2.easeInOut",
      });
    } else if (previousProgress.current < progress) {
      previousProgress.current = progress;
      progressTimeline.to(animationRef.current, {
        progress,
        duration: 0.2,
        ease: "Power2.easeInOut",
        // overwrite: progress === 1,
      });
    }
  };

  useEffect(() => {
    if (animationRef.current) {
      return;
    }

    // @ts-ignore
    const numbersRefs = [...wheelRef?.current.children];

    const numberHeight = numbersRefs[0].clientHeight;

    // const numLines = numbersRefs.length;
    const numLines = 12;
    // const radius = numLines * 33;
    const radius = numLines * numberHeight * 0.132;
    const angle = 360 / numLines;
    const origin = `50% 50% -${radius}px`;

    gsap.set(numbersRefs, {
      xPercent: -50,
      yPercent: -50,
      z: radius,
      rotationX: (index) => -angle * index,
      visibility: (index) => (index > 5 ? "hidden" : "visible"),
      transformOrigin: origin,
      onComplete: () => {
        gsap.set(wheelRef.current, {
          opacity: 1,
        });
      },
    });

    animationRef.current = gsap.to(wheelRef.current, {
      rotationX: (360 * (numbersRefs.length - 2)) / numLines,
      duration: numbersRefs.length,
      ease: "none",
      transformOrigin: "50% 50%",
      paused: true,
      onComplete: () => {
        const tl = gsap.timeline();
        tl.to(wheelRef.current, {
          rotationX: "+=30",
          duration: 0.5,
          ease: "Power1.easeIn",
        });
        tl.to(containerRef.current, {
          opacity: 0,
          duration: showLoaderOverlay ? 0.1 : 1,
          ease: "Power1.easeOut",
          onStart: onComplete,
          onComplete: () => {
            gsap.set(wheelRef.current, {
              display: "none",
            });
          },
        });
      },
      onUpdate: function (this: gsap.core.Tween) {
        const progress = Math.floor(this.progress() * 100);

        gsap.set(numbersRefs, {
          visibility: (index) => {
            if (progress < 5) {
              return index > progress + 5 ? "hidden" : "visible";
            }

            if (progress > numbersRefs.length - 5) {
              return index < progress - 5 ? "hidden" : "visible";
            }

            if (progress - 5 < index && index < progress + 5) {
              return "visible";
            }

            return "hidden";
          },
        });
      },
    });

    return () => {
      animationRef.current?.kill();
      animationRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationRef]);

  useEffect(() => {
    counter.current += 1;
    if (counter.current % 2 === 0 && dreiProgress < 100) {
      return;
    }

    goToProgress(animationRef, dreiProgress / 100);

    // return () => {
    //   clearTimeout(to);
    // };
  }, [dreiProgress]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.view}>
        <div className={styles.wheelContainer}>
          <div className={styles.shadowsContainer} />
          <div ref={wheelRef} className={styles.wheel}>
            {ARRAY_00_100.map((i) => (
              <div key={i} className={styles.number}>
                {i}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpinningLoader;
