import Wall from "./Wall";
import Post from "./Post";
import { useGarageContext } from "../../../../../context/garageContext";

export default function Walls() {
  const { screenSizeOffset } = useGarageContext();
  return (
    <>
      {/* left side */}
      <group position-x={screenSizeOffset}>
        <Wall position={[-7.5, 3.25, -10]} />
        <Post position={[-7.2, 3, -25]} />
        <Post position={[-7.2, 3, -15]} />
        <Post position={[-7.2, 3, -5]} />
      </group>

      {/* right side */}
      <group position-x={-screenSizeOffset}>
        <Wall position={[7.5, 3.25, -10]} />
        <Post position={[7.2, 3, -25]} />
        <Post position={[7.2, 3, -15]} />
        <Post position={[7.2, 3, -5]} />
      </group>
    </>
  );
}
