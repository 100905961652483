import GarageSceneQuestions from "../../organisms/garage-scene-questions/GarageSceneQuestions";

export default function GarageSceneOverlay({
  className,
}: {
  className?: string;
}) {
  return (
    <div className={className}>
      <GarageSceneQuestions />
    </div>
  );
}
