/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 Box_M_light.glb --transform -k -s -t
*/

import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Box_M_Light: Mesh;
  };
  materials: {
    ["Lower Cardboard"]: MeshStandardMaterial;
  };
};

export function BoxMLight(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Box_M_light.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.35}>
      <mesh
        name="Box_M_Light"
        castShadow
        receiveShadow
        geometry={nodes.Box_M_Light.geometry}
        material={materials["Lower Cardboard"]}
        // position={[-5.86, 0, 0]}
        scale={[0.65, 0.64, 0.64]}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/Box_M_light.glb");
