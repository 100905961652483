import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube011: Mesh;
    Cube011_1: Mesh;
    Cube011_2: Mesh;
  };
  materials: {
    ["Cabinet Base"]: MeshStandardMaterial;
    ["Cabinet Handles"]: MeshStandardMaterial;
    Wheels: MeshStandardMaterial;
  };
};

export default function Cabinet(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/cabinet.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.35}>
      <group
        name="Cube"
        position={[0, 0, 0]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.71, 1, 1]}
      >
        <mesh
          name="Cube011"
          castShadow
          receiveShadow
          geometry={nodes.Cube011.geometry}
          material={materials["Cabinet Base"]}
        />
        <mesh
          name="Cube011_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube011_1.geometry}
          material={materials["Cabinet Handles"]}
        />
        <mesh
          name="Cube011_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube011_2.geometry}
          material={materials.Wheels}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/cabinet.glb");
