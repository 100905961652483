import { MouseEvent, useEffect, useRef } from "react";
import styles from "./SuggestedQuestions.module.scss";
import gsap from "gsap";
import { useDeviceStateTracker } from "../../../hooks/useDeviceStateTracker";
import useAnalytics from "../../../hooks/useAnalytics";

type SuggestedQuestionsProps = {
  active: boolean;
  suggestedQuestions: Array<string>;
  onClick: (question: string) => void;
};

export default function SuggestedQuestions({
  active,
  suggestedQuestions,
  onClick,
}: SuggestedQuestionsProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (active) {
        gsap
          .timeline()
          .set(ref.current, {
            display: "grid",
          })
          .fromTo(
            ref.current.children,
            {
              opacity: 0,
              y: 15,
            },
            {
              opacity: 1,
              y: 0,
              stagger: 0.15,
              duration: 0.35,
              ease: "Power1.easeOut",
            }
          );
      } else {
        gsap
          .timeline()
          .fromTo(
            ref.current.children,
            {
              opacity: 1,
              y: 0,
            },
            {
              opacity: 0,
              y: -15,
              stagger: 0.15,
              duration: 0.35,
              ease: "Power1.easeOut",
            }
          )
          .set(ref.current, {
            display: "none",
          });
      }
    }
  }, [active]);

  const { deviceState } = useDeviceStateTracker();
  const { trackButtonClick } = useAnalytics();

  function handleSuggestedQuestionClick(
    e: MouseEvent<HTMLButtonElement>,
    question: string
  ) {
    trackButtonClick("ui interaction", "", e.currentTarget.innerText);
    onClick(question);
  }

  return (
    <div className={styles.suggestedQuestions} ref={ref}>
      {suggestedQuestions.map(
        (value, index) =>
          (deviceState > 1 || index < 3) && (
            <button
              onClick={(e) => handleSuggestedQuestionClick(e, value)}
              key={value}
            >
              {value}
            </button>
          )
      )}
    </div>
  );
}
