import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    X003: Mesh;
  };
  materials: {
    ["Metal Shelf"]: MeshStandardMaterial;
  };
};

export default function ShelfWithBoxes(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Industrial_Shelf.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.6}>
      <mesh
        name="X003"
        castShadow
        receiveShadow
        geometry={nodes.X003.geometry}
        material={materials["Metal Shelf"]}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/Industrial_Shelf.glb");
