import { AdaptiveDpr, AdaptiveEvents, Preload, Stats } from "@react-three/drei";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import SkipButton from "src/components/atoms/skip-button/SkipButton";
import BgAudio from "../../atoms/bg-audio/BgAudio";
import ScrollIndicator from "../../atoms/scroll-indicator/ScrollIndicator";
import Layout from "../../layout";
import SpinningLoader from "../../molecules/spinning-loader/SpinningLoader";
import CutSceneVideo from "../cut-scene-video/CutSceneVideo";
import GarageScene from "../../scenes/garage-scene/GarageScene";
import GarageSceneBackground from "../../scenes/garage-scene/GarageSceneBackground";
import GarageSceneOverlay from "../../scenes/garage-scene/GarageSceneOverlay";
import IntroScene from "../../scenes/intro-scene/IntroScene";
import { AiLoadingContextProvider } from "../../../context/aiLoadingContext";
import { useGarageContext } from "../../../context/garageContext";
import { useSkipContext } from "../../../context/skipContext";
import AudioListener from "../../../lib/AudioListener/AudioListener";
import styles from "./MainPage.module.scss";
import useUnmount from "../../../hooks/useUnmount";
import { Canvas } from "../../layout/canvas/Canvas";

gsap.registerPlugin(ScrollTrigger);

type MainPageProps = {
  start: boolean;
  showLoaderOverlay: boolean;
  setShowLoaderOverlay: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MainPage({
  start,
  showLoaderOverlay,
  setShowLoaderOverlay,
}: MainPageProps) {
  const { debug } = useGarageContext();
  const [playCutScene, setPlayCutScene] = useState<number>(0);
  const [activeScene, setActiveScene] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [garageDoorsInAnimationComplete, setGarageDoorsInAnimationComplete] =
    useState(false);
  const [sceneReady, setSceneReady] = useState(false);
  const [playBgAudio, setPlayBgAudio] = useState(false);
  const [isScrollIndicatorDone, setIsScrollIndicatorDone] = useState(false);

  const loaderContainerRef = useRef<HTMLDivElement>(null);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const audioListenerRef = useRef(new AudioListener());
  const cutSceneRef = useRef<HTMLDivElement>(null);
  const scrollIndicatorRef = useRef<HTMLDivElement>(null);

  const { isSkipping, toggleSkippingContext } = useSkipContext();
  const { backToStart, toggleBackToStart } = useSkipContext();

  const { asPath } = useRouter();

  useEffect(() => {
    if (asPath.includes("#ai")) {
      gsap.set(loaderContainerRef.current, {
        display: "none",
      });
    }
  }, [asPath]);

  useEffect(() => {
    if (asPath.includes("#ai")) {
      setSceneReady(true);
      gsap
        .timeline({
          onComplete: () => setActiveScene(2),
        })
        .set(cutSceneRef.current, {
          display: "block",
          opacity: 0,
        })
        .to(cutSceneRef.current, {
          opacity: 1,
          duration: 1,
          onStart: () => {
            setPlayCutScene(2);
          },
          onComplete: () => {
            gsap.to(canvasContainerRef.current, {
              y: 0,
              opacity: 1,
              duration: 1,
            });
          },
        });
    }
  }, [asPath]);

  useEffect(() => {
    if (!scrollIndicatorRef.current || !isScrollIndicatorDone) return;
    gsap.to(scrollIndicatorRef.current, {
      opacity: 0,
      duration: 1,
    });
  }, [isScrollIndicatorDone]);

  useEffect(() => {
    if (backToStart) {
      toggleBackToStart(false);
      setShowLoaderOverlay(true);
      setTimeout(() => {
        setActiveScene(0);
      }, 500);
    }
  }, [backToStart, toggleBackToStart, setShowLoaderOverlay]);

  function renderScene() {
    switch (activeScene) {
      case 1:
        return (
          <IntroScene
            onSceneComplete={goToGarageScene}
            onStartScrolling={() => {
              setIsScrollIndicatorDone(true);
            }}
            onIntroAnimationComplete={() => {
              setIsReady(true);
            }}
            start={sceneReady}
          />
        );
      case 2:
        return <GarageScene start={sceneReady} />;
      default:
        break;
    }
  }

  function renderSceneBackground() {
    switch (activeScene) {
      case 2:
        return <GarageSceneBackground className={styles.garageBackground} />;
      default:
        return null;
    }
  }

  function renderSceneOverlay() {
    switch (activeScene) {
      case 2:
        return (
          sceneReady && <GarageSceneOverlay className={styles.garageOverlay} />
        );
      default:
        return null;
    }
  }

  const startCutSceneAnimation = useCallback(() => {
    if (activeScene === 2) {
      return;
    }

    setSceneReady(false);
    gsap
      .timeline()
      .set(cutSceneRef.current, {
        display: "block",
        opacity: 0,
      })
      .to(cutSceneRef.current, {
        opacity: 1,
        duration: 1,
        onStart: () => {
          if (activeScene === 0) {
            setPlayCutScene(1);
            setActiveScene(1);
          }
        },
        onComplete: () => {
          if (activeScene === 1) {
            setPlayCutScene(2);
            setActiveScene(2);
          }
        },
      });
  }, [activeScene]);

  const removeCutSceneAndGoToNextScene = useCallback(() => {
    setPlayBgAudio(true);
    setSceneReady(true);
    gsap
      .timeline({
        onComplete: () => {
          setPlayCutScene(0);
        },
      })
      .to(cutSceneRef.current, {
        opacity: 0,
        duration: 0.75,
        ease: "none",
      })
      .set(cutSceneRef.current, {
        display: "none",
      });
  }, []);

  useEffect(() => {
    if (
      start &&
      garageDoorsInAnimationComplete &&
      activeScene === 0 &&
      !asPath.includes("#ai")
    ) {
      startCutSceneAnimation();
      audioListenerRef?.current.loadAudio("/assets/audio/IntroAI-Welkom.mp3");
    }
  }, [
    start,
    garageDoorsInAnimationComplete,
    startCutSceneAnimation,
    activeScene,
    asPath,
  ]);

  const goToGarageScene = useCallback(() => {
    audioListenerRef.current.fadeOut();
    startCutSceneAnimation();
  }, [startCutSceneAnimation]);

  useEffect(() => {
    if (activeScene !== 1) {
      toggleSkippingContext(false);
    }
  }, [activeScene, toggleSkippingContext]);

  useEffect(() => {
    if (isSkipping) {
      goToGarageScene();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkipping]);

  useUnmount(() => {
    audioListenerRef.current.destroy();
  });

  return (
    <>
      <Layout className={styles.layout} hasSound={activeScene > 0}>
        <div className={styles.loaderContainer} ref={loaderContainerRef}>
          {activeScene === 0 && (
            <SpinningLoader
              showLoaderOverlay={showLoaderOverlay}
              onComplete={() => {
                setGarageDoorsInAnimationComplete(true);
              }}
            />
          )}
        </div>

        <div className={styles.garage}>
          <div ref={cutSceneRef} className={styles.cutSceneCanvas}>
            {playCutScene < 2 && (
              <CutSceneVideo
                src="/assets/video/garage-doors-transition_2.mp4"
                play={playCutScene === 1}
                onCutSceneFinished={removeCutSceneAndGoToNextScene}
                half
                videoDuration={2500}
              />
            )}

            {start && (
              <CutSceneVideo
                src="/assets/video/cutscene.mp4"
                play={playCutScene === 2}
                onCutSceneFinished={removeCutSceneAndGoToNextScene}
                videoDuration={1500}
              />
            )}
          </div>

          <AiLoadingContextProvider>
            {renderSceneOverlay()}
            <div
              className={styles.garageCanvasContainer}
              ref={canvasContainerRef}
            >
              <Canvas
                shadows
                performance={{ min: 0.6 }}
                dpr={[1, 1.5]}
                gl={{
                  powerPreference: "high-performance",
                }}
              >
                <Suspense fallback={null}>
                  {renderScene()}
                  {debug && <Stats />}
                  <Preload all />
                  <AdaptiveDpr pixelated />
                  <AdaptiveEvents />
                </Suspense>
              </Canvas>
              {activeScene === 1 && isReady && (
                <div ref={scrollIndicatorRef}>
                  <ScrollIndicator />
                </div>
              )}
              <SkipButton />
            </div>

            {renderSceneBackground()}
          </AiLoadingContextProvider>
        </div>
      </Layout>

      {start && <BgAudio playing={playBgAudio} />}
    </>
  );
}
