import classNames from "classnames";
import { MouseEvent, useRef } from "react";
import { useSkipContext } from "src/context/skipContext";
import { useDeviceStateTracker } from "src/hooks/useDeviceStateTracker";
import styles from "./SkipButton.module.scss";
import useAnalytics from "../../../hooks/useAnalytics";

export default function SkipButton() {
  const { trackButtonClick } = useAnalytics();
  const containerRef = useRef<HTMLDivElement>(null);
  const { inSkippingContext, toggleSkip } = useSkipContext();
  const { deviceState } = useDeviceStateTracker(true);

  function handleSkipButtonClick(e: MouseEvent<HTMLButtonElement>) {
    trackButtonClick("navigation", "", e.currentTarget.innerText);
    toggleSkip(true);
  }

  return (
    <>
      {inSkippingContext && (
        <div
          className={classNames(styles.container, styles.visible)}
          ref={containerRef}
        >
          <button
            className={`${styles.buttonBottom} ${
              deviceState <= 2 ? "" : styles.desktop
            }`}
            onClick={handleSkipButtonClick}
          >
            Intro overslaan
          </button>
        </div>
      )}
    </>
  );
}
