import { useTexture } from "@react-three/drei";
import { RepeatWrapping } from "three";
import { skirtHeight, skirtWidth } from "./Wall";

const postSize = 1;

export default function Post(
  props: JSX.IntrinsicElements["group"] & {
    position: [x: number, y: number, z: number];
  }
) {
  const textures = useTexture(
    {
      map: "/assets/textures/plaster/beige_wall_diff.jpg",
      // displacementMap: "/assets/textures/plaster/beige_wall_disp.jpg",
      normalMap: "/assets/textures/plaster/beige_wall_normal.jpg",
      roughnessMap: "/assets/textures/plaster/beige_wall_rough.jpg",
      aoMap: "/assets/textures/plaster/beige_wall_ao.jpg",
    },
    (textures) => {
      if (Array.isArray(textures))
        textures.forEach((texture) => {
          texture.repeat.set(5, 5);
          texture.wrapT = RepeatWrapping;
          texture.wrapS = RepeatWrapping;
        });
    }
  );
  return (
    <group {...props}>
      <mesh receiveShadow castShadow>
        <boxGeometry args={[postSize * 0.5, 6.5, postSize]} />
        {/*<meshBasicMaterial color="red" />*/}
        <meshStandardMaterial {...textures} />
      </mesh>
      <mesh
        receiveShadow
        castShadow
        position-y={-props.position[1] + skirtHeight * 0.5}
      >
        <boxGeometry args={[postSize * 0.5 + skirtWidth, skirtHeight, 1.15]} />
        {/*<meshBasicMaterial color="red" />*/}
        <meshStandardMaterial {...textures} />
      </mesh>
    </group>
  );
}

useTexture.preload("/assets/textures/plaster/beige_wall_diff.jpg");
// useTexture.preload("/assets/textures/plaster/beige_wall_disp.jpg");
useTexture.preload("/assets/textures/plaster/beige_wall_normal.jpg");
useTexture.preload("/assets/textures/plaster/beige_wall_rough.jpg");
useTexture.preload("/assets/textures/plaster/beige_wall_ao.jpg");
