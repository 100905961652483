import { Text, useScroll } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { AudioLoader } from "three";
import { useGarageContext } from "../../../../../context/garageContext";
import { useSkipContext } from "../../../../../context/skipContext";
import AudioListener from "../../../../../lib/AudioListener/AudioListener";

export const texts: Array<{
  text: string;
  position: [x: number, y: number, z: number];
  audio: string;
  showSkipIntroButton?: boolean;
}> = [
  {
    text: "De garage.",
    position: [0, 2, 8.5],
    audio: "/assets/audio/texts/de-garage.mp3",
  },
  {
    text: "De ultieme sportwagen creëren: dat ging niet vanzelf. Voor het ontstaan van de eerste Porsche was een ondernemende mentaliteit nodig.",
    position: [0, 2, 4.5],
    audio: "/assets/audio/texts/de-ultieme-sportwagen.mp3",
  },
  {
    text: "Een droom realiseren vraagt om veerkracht. Door niet op te geven, werd in de garage vanuit een ruwe schets een iconisch sportwagenmerk geboren.",
    position: [0, 2, 2.3],
    audio: "/assets/audio/texts/een-droom-realiseren.mp3",
  },
  {
    text: "Bij Porsche noemen we deze instelling daarom \nde Garage Mentality.",
    position: [0, 2, 0.1],
    audio: "/assets/audio/texts/bij-Porsche-noemen.mp3",
    showSkipIntroButton: true,
  },
  {
    text: "Die staat voor ondernemerschap, \nambitie en innovatie.",
    position: [0, 2, -2.1],
    audio: "/assets/audio/texts/die-staat-voor-ondernemerschap.mp3",
  },
  {
    text: "De wereld is altijd klaar voor nieuwe dromen en de verfrissende ideeën die daaruit voortkomen.",
    position: [0, 2, -4.3],
    audio: "/assets/audio/texts/de-wereld-is.mp3",
  },
  {
    text: "Voor veel ambitieuze dromers is de garage de plek waar die ideeën vorm krijgen en verandering in gang wordt gezet.",
    position: [0, 2, -6.5],
    audio: "/assets/audio/texts/voor-veel-ambitieuze.mp3",
  },
  {
    text: "Dromen zijn niet te timen, het realiseren ervan wel. Doe het nu: maak werk van jouw droom en daarmee misschien wel van je nieuwe onderneming.",
    position: [0, 2, -8.7],
    audio: "/assets/audio/texts/dromen-zijn-niet.mp3",
  },
  {
    text: "Laat je met behulp van AI inspireren \ndoor succesvolle ondernemers.",
    position: [0, 2, -10.9],
    audio: "/assets/audio/texts/laat-je-inspireren.mp3",
  },
  {
    text: "Durf te vragen, \nje krijgt direct een antwoord.",
    position: [0, 2, -13.1],
    audio: "/assets/audio/texts/durf-te-vragen.mp3",
  },
];

export default function Texts({
  onComplete,
  start,
}: {
  onComplete: () => void;
  start?: boolean;
}) {
  const { toggleSkippingContext } = useSkipContext();
  const { smallScreen } = useGarageContext();
  const scroll = useScroll();
  const audioListener = useRef(new AudioListener());
  const textRefs = useRef<Map<number, any>>(null);
  const audioPlayedCount = useRef(0);
  const onCompleteCalled = useRef(false);

  useEffect(() => {
    const audioListenerRef = audioListener.current;
    if (textRefs.current) {
      textRefs.current.forEach((text) => {
        text.material.opacity = 0;
        text.material.fog = false;
      });
    }

    return () => {
      if (textRefs.current) {
        audioListenerRef.fadeOut();
        textRefs.current.forEach((text) => {
          text.dispose();
        });
      }
    };
  }, []);

  useFrame(() => {
    if (textRefs.current) {
      textRefs.current.forEach((text, i) => {
        const active =
          start &&
          (scroll.visible(i / (texts.length + 1), 1 / (texts.length + 1)) ||
            i === 0);
        const progress = scroll.range(
          i / (texts.length + 1),
          1 / (texts.length + 1)
        );
        text.material.opacity = i === 0 ? 1 : progress;
        text.material.fog = false;
        if (i > 0 && progress > 0.7) {
          text.fillOpacity = Math.max(1 - (progress - 0.7) * 4, 0);
        } else if (i === 0) {
          text.fillOpacity = Math.max(1 - progress * 4, 0);
        }

        if (active && i >= audioPlayedCount.current) {
          audioListener.current.fadeOutAndPlayNewAudio(texts[i].audio);
          audioPlayedCount.current = i + 1;

          if (texts[i].showSkipIntroButton) {
            toggleSkippingContext(true);
          }
        }

        if (!onCompleteCalled.current && scroll.offset >= 0.915) {
          onComplete();
          onCompleteCalled.current = true;
        }
      });
    }
  });

  function getMap() {
    if (!textRefs.current) {
      // Initialize the Map on first usage.
      // @ts-ignore
      textRefs.current = new Map();
    }
    return textRefs.current;
  }

  return (
    <>
      {texts.map((text, i) => (
        <Text
          key={text.position.join("-")}
          color="white"
          anchorX="center"
          anchorY="middle"
          textAlign="center"
          position={text.position}
          fontSize={start ? (smallScreen ? 0.07 : 0.08) : 0}
          font="/assets/font/porsche-next/PorscheNext-Regular.otf"
          lineHeight={1.1}
          maxWidth={smallScreen ? 0.75 : 1.6}
          ref={(node) => {
            const map = getMap();
            if (node) {
              map.set(i, node);
            }
          }}
        >
          {text.text}
        </Text>
      ))}
    </>
  );
}

useLoader.preload(AudioLoader, texts[0].audio);
