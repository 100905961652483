import classNames from "classnames";
import styles from "./GarageScene.module.scss";
import { useGarageContext } from "../../../context/garageContext";
import Image from "next/image";

export default function GarageSceneBackground({
  className,
}: {
  className?: string;
}) {
  const { audioPlaying } = useGarageContext();
  return (
    <div className={className}>
      <div className={styles.background}>
        <Image
          src="/assets/images/garage_bg.jpg"
          alt="Werkplaats in een garage met een geparkeerde Porsche"
          fill
        />
      </div>
      <div
        className={classNames(styles.redGlow, {
          [styles.playing]: audioPlaying,
        })}
      />
    </div>
  );
}
