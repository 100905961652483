import React, { useEffect, useState } from "react";
import { SkipContextProvider } from "../context/skipContext";
import { GarageContextProvider } from "../context/garageContext";
import { useDeviceStateTracker } from "../hooks/useDeviceStateTracker";
import MainPage from "../components/organisms/main-page/MainPage";
import LoaderOverlay from "../components/organisms/loader-overlay/LoaderOverlay";
import { useRouter } from "next/router";
import useIsomorphicLayoutEffect from "../hooks/useIsomorphicLayoutEffect";

const Index = () => {
  const { asPath } = useRouter();

  const { deviceState } = useDeviceStateTracker(true);
  const [debug, setDebug] = useState(false);
  const [showLoaderOverlay, setShowLoaderOverlay] = useState(true);

  useEffect(() => {
    if (window?.location?.hash && window.location.hash === "#debug") {
      setDebug(true);
    }
  }, []);

  useEffect(() => {
    fetch(`/api/getRagApplication?initialization=true`, {});
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (asPath.includes("#ai")) {
      setShowLoaderOverlay(false);
    }
  }, [asPath]);

  return (
    <>
      <LoaderOverlay
        onButtonClick={() => setShowLoaderOverlay(false)}
        hidden={!showLoaderOverlay}
      />
      <SkipContextProvider>
        <GarageContextProvider debug={debug} smallScreen={deviceState <= 2}>
          <MainPage
            start={!showLoaderOverlay}
            showLoaderOverlay={showLoaderOverlay}
            setShowLoaderOverlay={setShowLoaderOverlay}
          />
        </GarageContextProvider>
      </SkipContextProvider>
    </>
  );
};

export default Index;
