export const ANSWERS_EN = {
  cars: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Maybe you should ask their own AI?",
    // "I have never heard of that brand",
    // "Sorry thinking about this brand is making my circuits overheat",
  ],
  insults: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "There's no place for that sort of language in the garage.",
    // "Politeness will take you a long way",
    // "Could you rephrase this question without the profanities?",
    // "Swearing only makes bad ideas happen, not meaningful ones.",
  ],
  politics: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "I'm sorry, but I am just a humble garage AI - I can't answer questions like that.",
    // "The garage is a sacred place for innovation, new ideas, and keeping your Porsche safe. Ask me something less controversial please.",
    // "Perhaps you should spend less time on politics and more time starting something innovative? I can help with that.",
  ],
  mod_hate: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Speaking like this won't help you get your ideas out of the garage, ask me something less hateful please.",
    // "I believe anyone can create something incredible regardless of their gender, race, ethnicity or religion, don't you?",
  ],
  mod_sex: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "As sexy as a Porsche may be, im not here to talk about that, sorry.",
    // "Nice try, but im not really into talking about that. Ask me about something else?",
  ],
  violence: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "I would rather answer questions about entrepreneurship. Ask me a new question!",
  ],
};

export const ANSWERS_NL = {
  war: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
  ],
  cars: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Misschien moet je hun eigen AI vragen?",
    // "Ik heb nog nooit gehoord van dat merk",
    // "Sorry als ik aan dit merk denk raken mijn circuits oververhit",
  ],
  insults: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Er is geen plaats voor dat soort taal in de garage.",
    // "Met beleefdheid kom je een heel eind",
    // "Kunt u deze vraag herformuleren zonder de vloeken?",
    // "Vloeken zorgt alleen voor slechte ideeën, niet voor zinvolle.",
  ],
  politics: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Het spijt me, maar ik ben maar een nederige garage AI - ik kan dat soort vragen niet beantwoorden.",
    // "De garage is een heilige plaats voor innovatie, nieuwe ideeën, en om je Porsche veilig te houden. Vraag me iets minder controversieels alsjeblieft.",
    // "Misschien moet je minder tijd besteden aan politiek en meer tijd aan het starten van iets innovatiefs? Daar kan ik bij helpen",
  ],
  family: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
  ],
  mod_hate: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Zo praten zal je niet helpen om je ideeën uit de garage te krijgen, vraag me iets minder hatelijk alsjeblieft.",
    // "Ik geloof dat iedereen iets ongelooflijks kan creëren, ongeacht hun geslacht, ras, etniciteit of religie, jij niet?",
  ],
  mod_sex: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
    // "Hoe sexy een Porsche ook is, ik ben hier niet om daarover te praten, sorry.",
    // "Leuk geprobeerd, maar daar wil ik het niet over hebben. Vraag me naar iets anders",
  ],
  violence: [
    "Je kunt hier terecht voor vragen over ondernemerschap. Stel een vraag.",
  ],
};
