/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 Poster_Holder_L.glb --transform -k -s -t
*/

import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder004: Mesh;
    Cylinder004_1: Mesh;
  };
  materials: {
    Outer: MeshStandardMaterial;
    Caps: MeshStandardMaterial;
  };
};

export default function PosterHolderL(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Poster_Holder_L.glb"
  ) as unknown as GLTFResult;

  return (
    <group {...props} scale={0.45}>
      <mesh
        name="Cylinder004"
        castShadow
        receiveShadow
        geometry={nodes.Cylinder004.geometry}
        material={materials.Outer}
      />
      <mesh
        name="Cylinder004_1"
        castShadow
        receiveShadow
        geometry={nodes.Cylinder004_1.geometry}
        material={materials.Caps}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/Poster_Holder_L.glb");
