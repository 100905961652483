/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 Box_L_dark.glb --transform -k -s -t
*/

import { Mesh, MeshStandardMaterial } from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Box_L: Mesh;
  };
  materials: {
    ["Cardboard Box Final"]: MeshStandardMaterial;
  };
};

export function BoxL(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/Box_L.glb"
  ) as unknown as GLTFResult;
  return (
    <group {...props} scale={0.35}>
      <mesh
        name="Box_L"
        castShadow
        receiveShadow
        geometry={nodes.Box_L.geometry}
        material={materials["Cardboard Box Final"]}
        // rotation={[0, Math.PI / 2, 0]}
        scale={1.03}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/Box_L.glb");
