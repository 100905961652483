export const GOOD_QUESTIONS_EN = [
  "How do I find the most talented employees for my startup?",
  "What are points to keep in mind when creating a startup?",
  "What qualities are the most important for a leader?",
  // "How can I start a new business?",
  // "Im starting a new company. Should I self fund or work with investors?",
  // "What are some of the biggest mistakes a CEO can make?",
];

export const GOOD_QUESTIONS_NL = [
  "Wat is de Garage Mentality?",
  "Hoe start ik mijn onderneming?",
  "Hoe kan ik mijn onderneming naar het volgende niveau tillen?",
  // "Hoe vind ik de meest getalenteerde werknemers voor mijn startup?",
  // "Wat zijn punten om in gedachten te houden bij het opzetten van een startup?",
  // "Welke kwaliteiten zijn het belangrijkst voor een leider?",
  // "Hoe kan ik een nieuw bedrijf starten?",
  // "Voor mijn nieuwe bedrijf, moet ik zelf financieren of investeerders gebruiken?",
  // "Wat zijn enkele van de grootste fouten die een CEO kan maken?",
];
