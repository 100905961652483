export const WAR_WORDS = {
  ss: true,
  schutzstaffel: true,
  "ss-ehrenring": true,
  "war merit": true,
  mof: true,
  moffen: true,
  "adolf rosenberger": true,
  "tiger tank": true,
  panzerjäger: true,
  paramilitair: true,
  1940: true,
  1941: true,
  1942: true,
  1944: true,
  1945: true,
  prison: true,
  gevangenis: true,
  panzer: true,
  "super-heavy tank": true,
  "vk 4501": true,
  "the elefant": true,
  tank: true,
  tanks: true,
  tanken: true,
  pistool: true,
  pistools: true,
  pistoolen: true,
  buks: true,
  geweer: true,
  jachtgeweer: true,
  gun: true,
  guns: true,
  "weapon systems": true,
  wapensyteeem: true,
  "ferdinand tank": true,
  "88mm": true,
  "anti-aircraft": true,
  imprisonment: true,
  "forced labor": true,
  wartime: true,
  "slave labor": true,
};
