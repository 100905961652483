// import { CAR_BRANDS } from "./car_brands";
import { ENGLISH_SWEAR_WORDS } from "./english_swear_words";
import { DUTCH_SWEAR_WORDS } from "./dutch_swear_words";
import { SENSITIVE_FIGURES } from "./sensitive_figures";
import { WAR_WORDS } from "./war_words";
import { PORSCHE_FAMILY } from "./porsche_family";

export const KEYWORDS = {
  // cars: CAR_BRANDS,
  insults: { ...ENGLISH_SWEAR_WORDS, ...DUTCH_SWEAR_WORDS },
  politics: SENSITIVE_FIGURES,
  family: PORSCHE_FAMILY,
  war: WAR_WORDS,
};
